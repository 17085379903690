import { Box } from "@mui/material";
import Drawer from "../../components/drawers/Drawer";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import pstV1ApiService from "../../../../services/apis/PstV1ApiService";
import Response from "../../../../services/apis/Response";
import { IConfiguratorFromTemplateResponse } from "../../components/interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../../components/interfaces/IAttribute";
import Error from "../../components/errorHandler/Error";
import PARAMS from "../../constants/PARAMS";
import { ConfiguratorConfigResponse } from "../../interfaces/ConfiguratorConfigResponse";
import ConfiguratorDataContextProvider from "../../contexts/ConfiguratorDataContext";
import axios from "axios";
import { hostAppApplyCover, hostAppRemoveCover } from "../../helpers/hostAppHTMLModifier";

const DrawerLaunch = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isValidConfiguration, setIsValidConfiguration] = useState<boolean>(false);
  const location = useLocation();
  const tids = new URLSearchParams(location.search).get(PARAMS.TIDS);
  const pid = new URLSearchParams(location.search).get(PARAMS.PID);
  const cid = new URLSearchParams(location.search).get(PARAMS.CID);
  const configurator = new URLSearchParams(location.search).get(PARAMS.CONFIGURATOR);
  const searchParams = new URLSearchParams(location.search);
  const [configFromTemplateData, setConfigFromTemplateData] = useState<IConfiguratorFromTemplateResponse | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>();
  let tempConfigFromTemplateData = configFromTemplateData;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(PARAMS.CONFIGURATOR)) {
      const url = window.location.href;
      const urlParams = new URLSearchParams(url.split("?")[1]);
      const paramCid = urlParams.get(PARAMS.CID);
      if (pid && !paramCid) {
        loadConfiguratorFromPID(pid);
      } else {
        paramCid !== null ? getConfigurationTemplate(paramCid) : loadConfiguratorFromTemplate();
      }
      if (!openDrawer) {
        setOpenDrawer(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (openDrawer && !pid && !cid) {
      hostAppApplyCover();
    }
  }, [openDrawer, location]);

  useEffect(() => {
    if (configFromTemplateData?.state !== "incomplete" && configFromTemplateData?.state !== undefined) {
      setIsValidConfiguration(true);
    }
  }, [configFromTemplateData]);

  const formatResponseData = (response: any) => {
    const formatedResponse: IConfiguratorFromTemplateResponse = {
      ...response.data,
      attributes: [],
    };
    for (const key in response.data) {
      if (response.data[key].class) {
        let attr: any = response.data[key];
        let attribute: IAttribute = {
          ...attr,
          isShowing: true,
        };
        formatedResponse.attributes?.push(attribute);
      }
    }
    setConfigFromTemplateData(formatedResponse);
    setIsLoading(false);
  };

  const getConfigurationTemplate = async (cid: string) => {
    try {
      setIsLoading(true);
      const result = await pstV1ApiService.getConfigurationTemplate(cid);
      const response: any = new Response(result);
      if (response.isSuccessful()) {
        formatResponseData(response);
        setIsValidConfiguration(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("error response in get Configuration Template:", e.response);
      }
      handleApiErrorFunctions();
    }
  };

  const loadConfiguratorFromTemplate = async (passedTids?: string) => {
    try {
      setIsLoading(true);
      const hasTids = passedTids ? passedTids : tids;
      const result = await pstV1ApiService.getLoadConfiguratorFromTemplate(hasTids, undefined, undefined);
      const response: any = new Response(result);
      if (response.isSuccessful()) {
        formatResponseData(response);
        setIsValidConfiguration(false);
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        console.error("error response in load Configurator From Template:", e.response);
      }
      handleApiErrorFunctions();
    }
  };

  const loadConfiguratorFromPID = async (pid: string) => {
    try {
      setIsLoading(true);
      const result = await pstV1ApiService.loadConfigurationFromPID(pid);
      const response: any = new Response(result);
      if (response.isSuccessful()) {
        formatResponseData(response);
        setIsValidConfiguration(false);
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        console.error("error response in load Configurator From PID:", e.response);
      }
      handleApiErrorFunctions();
    }
  };

  const resetConfiguration = () => {
    const searchParams = new URLSearchParams(location.search);
    const paramTids = searchParams.get(PARAMS.TIDS);
    if (searchParams.has(PARAMS.CID)) {
      searchParams.delete(PARAMS.CID);
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
    if (paramTids) {
      loadConfiguratorFromTemplate(paramTids);
    }
  };

  const updateItemEnum = (key: string, update: Partial<IAttribute>) => {
    let updatedItem: IAttribute;
    const itemAtr = tempConfigFromTemplateData?.attributes?.find(atr => atr.id === key);
    if (itemAtr) {
      updatedItem = { ...itemAtr, ...update };

      const index = tempConfigFromTemplateData?.attributes?.findIndex(atr => atr.id === key);

      if (index !== -1 && index !== undefined && tempConfigFromTemplateData?.attributes) {
        const updatedAttributes = [
          ...tempConfigFromTemplateData?.attributes?.slice(0, index),
          updatedItem,
          ...tempConfigFromTemplateData?.attributes?.slice(index + 1),
        ];
        const updatedData: IConfiguratorFromTemplateResponse = {
          ...tempConfigFromTemplateData,
          attributes: updatedAttributes,
        };
        tempConfigFromTemplateData = updatedData;
      }
    }

    for (const k in update) {
      if (tempConfigFromTemplateData && key in tempConfigFromTemplateData) {
        if (k in tempConfigFromTemplateData[key]) {
          const updatedData: IConfiguratorFromTemplateResponse = {
            ...tempConfigFromTemplateData,
            [key]: {
              ...tempConfigFromTemplateData[key],
              [k]: update[k as keyof IAttribute],
            },
          };
          tempConfigFromTemplateData = updatedData;
        }
      }
    }
  };

  const updateConfigData = (attribute: IAttribute, response: ConfiguratorConfigResponse) => {
    if (tempConfigFromTemplateData && response !== null && response[attribute.id]) {
      for (const key in tempConfigFromTemplateData) {
        if (key === attribute.id) {
          tempConfigFromTemplateData[key].value = response[key].value;
          if (attribute.userModAttribute) {
            tempConfigFromTemplateData[key].userModAttribute = attribute.userModAttribute;
          }
        }
      }
      const updatedData: IConfiguratorFromTemplateResponse = {
        ...tempConfigFromTemplateData,
        pid: response.pid ? response.pid : tempConfigFromTemplateData.pid,
        cid: tempConfigFromTemplateData.cid,
        state: response.state ? response.state : tempConfigFromTemplateData.state,
        properties: tempConfigFromTemplateData.properties,
      };
      console.log("state", response.state);
      console.log("pid", response.pid);
      console.log("cid", tempConfigFromTemplateData.cid);
      if (JSON.stringify(tempConfigFromTemplateData) !== JSON.stringify(updatedData)) {
        tempConfigFromTemplateData = updatedData;
      } else {
        console.log("response data is the same");
      }
    }

    try {
      if (response[attribute.id].value) {
        updateItemEnum(attribute.id, { value: response[attribute.id].value });
      }
    } catch (e) {
      console.log("respone attribute id value not found");
    }
    for (const key in response) {
      if (key !== attribute.id) {
        if (response[key].isSelected != null) {
          updateItemEnum(key, { isSelected: response[key].isSelected });
        }
        if (response[key].isAllowed != null) {
          updateItemEnum(key, { isAllowed: response[key].isAllowed });
        }
        if (response[key].isReqOne !== undefined) {
          updateItemEnum(key, { isReqOne: response[key].isReqOne });
        }
        if (response[key].hasSelectables !== undefined) {
          updateItemEnum(key, { hasSelectables: response[key].hasSelectables });
        }
        if (response[key].value) {
          updateItemEnum(key, { value: response[key].value });
        }
        if (response[key].qty) {
          updateItemEnum(key, { qty: response[key].qty });
        }
        if (response[key].auxText) {
          updateItemEnum(key, { auxText: response[key].auxText });
        }
      }
    }
    for (const key in response) {
      if (key === attribute.id) {
        if (response[key].isSelected != null) {
          updateItemEnum(key, { isSelected: response[key].isSelected });
        }
        if (response[key].isAllowed != null) {
          updateItemEnum(key, { isAllowed: response[key].isAllowed });
        }
        if (response[key].isReqOne !== undefined) {
          updateItemEnum(key, { isReqOne: response[key].isReqOne });
        }
        if (response[key].hasSelectables !== undefined) {
          updateItemEnum(key, { hasSelectables: response[key].hasSelectables });
        }
        if (response[key].value) {
          updateItemEnum(key, { value: response[key].value });
        }
        if (response[key].qty || typeof response[key].qty === "number") {
          updateItemEnum(key, { qty: response[key].qty });
        }
        if (response[key].auxText) {
          updateItemEnum(key, { auxText: response[key].auxText });
        }
      }
    }

    setConfigFromTemplateData(tempConfigFromTemplateData);
  };

  const updateConfigurator = async (attributes: IAttribute[] | undefined, attribute: IAttribute) => {
    setIsLoading(true);
    try {
      if (configFromTemplateData) {
        const result = await pstV1ApiService.getToggleConfiguration(configFromTemplateData.cid, attribute.id);
        const response = new Response(result);
        if (response.isSuccessful()) {
          if (response.data.state?.length > 0) {
            setIsValidConfiguration(response.data.state === "complete");
          }
          updateConfigData(attribute, response.data);
          setIsLoading(false);
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("error response in update Configurator:", e.response);
      }
      handleApiErrorFunctions();
    }
  };

  const updateConfiguratorQuantity = async (attributes: IAttribute[] | undefined, attribute: IAttribute, qty: string) => {
    setIsLoading(true);
    try {
      if (configFromTemplateData) {
        const result = await pstV1ApiService.setQuantity(configFromTemplateData.cid, attribute.id, qty);
        const response = new Response(result);
        if (response.isSuccessful()) {
          if (response.data.state?.length > 0) {
            setIsValidConfiguration(response.data.state === "complete");
          }
          updateConfigData(attribute, response.data);
          setIsLoading(false);
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("error response in update Configurator Quantity:", e.response);
      }
      handleApiErrorFunctions();
    }
  };

  const updateConfiguratorWithUserEnteredValue = async (attribute: IAttribute, value: string) => {
    setIsLoading(true);
    try {
      if (configFromTemplateData) {
        const result = await pstV1ApiService.getSetAttributeValue(configFromTemplateData.cid, attribute.id, value);
        const response = new Response(result);
        if (response.isSuccessful()) {
          setIsValidConfiguration(response.data.state === "complete");
          updateConfigData(attribute, response.data);
          setIsLoading(false);
        }
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        console.error("error response in update Configurator With User Entered Value:", e.response);
      }
      handleApiErrorFunctions();
    }
  };

  const handleApiErrorFunctions = () => {
    setIsLoading(false);
    handleConfiguratorDrawerClose();
    setShowErrorMessage(true);
  };

  const handleConfiguratorDrawerClose = () => {
    if (configurator) {
      searchParams.delete(PARAMS.CONFIGURATOR);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
    hostAppRemoveCover();
    setIsValidConfiguration(false);
    setOpenDrawer(false);
  };

  const handleQuantityAdd = (attribute: IAttribute) => {
    const qty = attribute.qty ? attribute.qty : 0;
    const updatedQty = qty + 1;
    updateConfiguratorQuantity(undefined, attribute, updatedQty.toString());
  };

  const handleQuantitySubtract = (attribute: IAttribute) => {
    const qty = attribute.qty ? attribute.qty : 0;
    const updatedQty = qty - 1;
    updateConfiguratorQuantity(undefined, attribute, updatedQty.toString());
  };

  return (
    <>
      {configFromTemplateData && openDrawer && (
        <ConfiguratorDataContextProvider>
          <Drawer
            isLoading={isLoading}
            drawerPosition="right"
            setOpenDrawer={openDrawer}
            handleConfiguratorDrawerClose={handleConfiguratorDrawerClose}
            isValidConfiguration={isValidConfiguration}
            configFromTemplateDataFromParent={configFromTemplateData}
            updateConfigurator={updateConfigurator}
            updateConfiguratorWithUserEnteredValue={updateConfiguratorWithUserEnteredValue}
            resetConfiguration={resetConfiguration}
            quantityAdd={handleQuantityAdd}
            quantitySubtract={handleQuantitySubtract}
          />
        </ConfiguratorDataContextProvider>
      )}
      {showErrorMessage && <Error drawerPosition="right" />}
    </>
  );
};

export default DrawerLaunch;
