import { Box, Button, IconButton, InputAdornment, InputBase, Paper, TextField, Typography } from "@mui/material";
import { SearchIcon } from "@rockwell-automation-inc/ra-meatball";
import { useEffect, useState } from "react";
import { useConfiguratorData } from "../../contexts/ConfiguratorDataContext";
import { IAttribute } from "../interfaces/IAttribute";

interface SearchProps {
  handleAttributeSearchSubmit: (query: string) => void;
  resetIsShowing: () => void;
  selectedAttribute: IAttribute;
}

const Search = ({ handleAttributeSearchSubmit, resetIsShowing, selectedAttribute }: SearchProps) => {
  const { searchQuery, setSearchQuery } = useConfiguratorData();
  const maxQueryLength: number = 20;
  const errorMessage: string = `Search must be less than ${maxQueryLength} characters`;

  const searchButtonDisabled = searchQuery.length == 0 || searchQuery.length >= maxQueryLength;
  const searchFieldDisabled = searchQuery.length >= maxQueryLength;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= maxQueryLength) {
      setSearchQuery(event.target.value);
    }
    if (event.target.value.length === 0 || event.target.value === undefined) {
      resetIsShowing();
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAttributeSearchSubmit(searchQuery);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      display="flex"
      flexDirection="row"
      position="relative"
      sx={{
        width: "100%",
        height: "80px",
        paddingTop: "10px",
        visibility: selectedAttribute.class === "String" ? "hidden" : "",
      }}>
      <TextField
        sx={{ width: "100%" }}
        error={searchFieldDisabled}
        label="Search"
        helperText={searchFieldDisabled ? errorMessage : ""}
        value={searchQuery}
        onChange={handleQueryChange}
        onKeyDown={event => handleKeyPress(event)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={searchButtonDisabled}
                aria-label="Search"
                disableRipple
                sx={{
                  padding: "7px 22px 7px 22px",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  backgroundColor: "#2A79C4",
                  width: "62px",
                  height: "32px",
                }}
                onClick={() => handleAttributeSearchSubmit(searchQuery)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Search;
