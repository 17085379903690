import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { ProductTabPanel } from "../productTabPanel/ProductTabPanel";
import Accessories from "../accessories/Accessories";
import CadenasDisplay from "../cadenas/Cadenas";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import { SelectableAccessory } from "../../interfaces/Accessory";
import { User } from "oidc-client";
import { AlternativeProductTabs } from "../AlternativeProductTabs/AlternativeProductTabs";
import ProductDocuments from "../productDocuments/ProductDocuments";
import ProductSpecifications from "../productSpecifications/ProductSpecifications";

interface ProductTabsProps {
  product: DetailedProduct | undefined;
  setSelectedAccessories: (accessories: SelectableAccessory[]) => void;
  user: User | undefined;
}

export const ProductTabs = (props: ProductTabsProps) => {
  const { product, setSelectedAccessories, user } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (indexProducts === newValue) {
      const tabClick = new CustomEvent("alternativeProductsClick", {
        detail: {
          action: "Alternative Product Click",
          properties: {
            category: "WebApp",
            label: "Alternative Products",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (indexModel === newValue) {
      const tabClick = new CustomEvent("modelViewerClick", {
        detail: {
          action: "Model Viewer Click",
          properties: {
            category: "WebApp",
            label: "Model Viewer",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (indexAccessories === newValue) {
      const tabClick = new CustomEvent("suggestedAccessoriesClick", {
        detail: {
          action: "Suggested Accessories Click",
          properties: {
            category: "WebApp",
            label: "Suggested Accessories",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (indexDocuments === newValue) {
      const tabClick = new CustomEvent("documentsClick", {
        detail: {
          action: "Documents Click",
          properties: {
            category: "WebApp",
            label: "Documents",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (indexSpecifications === newValue) {
      const tabClick = new CustomEvent("specificationsClick", {
        detail: {
          action: "Specifications Click",
          properties: {
            category: "WebApp",
            label: "Specifications",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `product-tab-${index}`,
      "aria-controls": `product-tabpanel-${index}`,
    };
  }

  const indexAccessories = 0;
  const indexProducts = product?.accessories !== undefined && product?.accessories.length > 0 ? 1 : 0;
  const indexModel = product?.accessories !== undefined && product?.accessories.length > 0 ? 2 : 1;
  const indexDocuments = product?.accessories !== undefined && product?.accessories.length > 0 ? 3 : 2;
  const indexSpecifications = product?.accessories !== undefined && product?.accessories.length > 0 ? 4 : 3;

  return (
    <>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="product tabs">
          {product?.accessories !== undefined && product.accessories.length > 0 && (
            <Tab label="Suggested Accessories" {...a11yProps(indexAccessories)} />
          )}
          <Tab label="Alternative Products" {...a11yProps(indexProducts)} />
          <Tab label="Drawings" {...a11yProps(indexModel)} />
          <Tab label="Documents" {...a11yProps(indexDocuments)} />
          <Tab label="Specifications" {...a11yProps(indexSpecifications)} />
        </Tabs>
      </Box>
      {product?.accessories !== undefined && product.accessories.length > 0 && (
        <ProductTabPanel value={value} index={indexAccessories}>
          <Accessories accessories={product?.accessories} setSelectedAccessories={setSelectedAccessories} />
        </ProductTabPanel>
      )}
      <ProductTabPanel value={value} index={indexProducts}>
        <AlternativeProductTabs catalogNumber={product?.catalogNumber || ""} user={user}></AlternativeProductTabs>
      </ProductTabPanel>
      <ProductTabPanel value={value} index={indexModel}>
        <CadenasDisplay catalogNumber={product?.catalogNumber} loggedIn={!!user} documents={product?.documents} />
      </ProductTabPanel>
      <ProductTabPanel value={value} index={indexDocuments}>
        <ProductDocuments documents={product?.documents} />
      </ProductTabPanel>
      <ProductTabPanel value={value} index={indexSpecifications}>
        <ProductSpecifications product={product} />
      </ProductTabPanel>
    </>
  );
};
