import { Box, Typography } from "@mui/material";
import "./Aside.scss";
import React from "react";

interface AsideProps {
  displayText: React.ReactNode;
}

const Aside: React.FC<AsideProps> = ({ displayText }) => {
  return (
    <>
      <Box
        component="aside"
        style={{
          transition: "all 1s ease 0s",
          zIndex: 91,
          width: "26rem",
          boxShadow: "0 1px 5px 0 rgb(0 0 0 / 25%)",
          background: "black",
        }}
        sx={{
          pt: 2,
          pb: 1.5,
          px: 2,
        }}
        position="relative">
        <Box sx={{ mb: 3, pl: 2 }} component="ul">
          <Typography variant="subtitle2" component="li" color="#fff" lineHeight="1.5rem" fontSize="1rem" sx={{ ml: 1 }}>
            {displayText}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Aside;
