import { styled } from "@mui/material/styles";
import { TableContainer, Table, TableBody, TableRow, TableCell as MuiTableCell, Typography } from "@mui/material";
import { DetailedProduct } from "../../interfaces/DetailedProduct";

interface ProductSpecificationsProps {
  product: DetailedProduct | undefined;
}

const ProductSpecifications = (props: ProductSpecificationsProps) => {
  const { product } = props;

  const packageQuantity = product?.packageQuantity !== 1 ? "Units" : "Unit";
  const orderQuantity = product?.minOrderQuantity !== 1 ? "Units" : "Unit";
  const TableCell = styled(MuiTableCell)`
    padding: 0 0 0.5rem;
    border: 0;
    height: 32px;
  `;

  const TableCellHeading = styled(TableCell)`
    font-weight: bold;
    max-width: 160px;
  `;

  return (
    <>
      <Typography fontWeight="bold" fontSize="16px" sx={{ mb: 2 }}>
        Specifications
      </Typography>
      <TableContainer sx={{ width: 600, display: "flex" }}>
        <Table sx={{ mr: "30px" }}>
          <TableBody>
            <TableRow>
              <TableCellHeading>Length: </TableCellHeading>
              <TableCell>
                {product?.materialUoM?.length} {product?.materialUoM?.uoMdimension}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Width: </TableCellHeading>
              <TableCell>
                {product?.materialUoM?.width} {product?.materialUoM?.uoMdimension}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Height: </TableCellHeading>
              <TableCell>
                {product?.materialUoM?.height} {product?.materialUoM?.uoMdimension}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Gross Weight: </TableCellHeading>
              <TableCell>
                {product?.materialUoM?.grossWeight} {product?.materialUoM?.uoMweight}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>UPC: </TableCellHeading>
              <TableCell>{product?.upc}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellHeading>Part Number: </TableCellHeading>
              <TableCell>{product?.partNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Country of Origin: </TableCellHeading>
              <TableCell>{product?.countryofOrigin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Harmonized Tarriff Code: </TableCellHeading>
              <TableCell>{product?.harmonizedTarriffCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Package Quantity: </TableCellHeading>
              <TableCell>
                {product?.packageQuantity} {packageQuantity}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Minimum Order Qty: </TableCellHeading>
              <TableCell>
                {product?.minOrderQuantity} {orderQuantity}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeading>Repairable Status: </TableCellHeading>
              <TableCell>{product?.repairableStatus}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductSpecifications;
