import React, { Fragment, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  Typography,
  Divider,
  Box,
  Unstable_Grid2 as Grid,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Stack,
  Tooltip,
  useTheme,
  Container,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterAlt";
import SearchSidebar from "../searchSidebar/SearchSidebar";
import SearchCriteriaAttributes from "../../interfaces/SearchCriteriaAttributes";
import { PricingAndAvailability } from "../../interfaces/PricingAndAvailability";
import SearchProductAttribute from "../../interfaces/SearchProductAttribute";
import SearchProductAttributeGroup from "../../interfaces/SearchProductAttributeGroup";
import SearchCriteriaProducts from "../../interfaces/SearchCriteriaProducts";
import PageSelector from "../common/PageSelector/PageSelector";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setNumberOfResultsPerPage } from "../../redux/slices/numberOfResultsPerPageSlice";
import { useSelector } from "react-redux";
import CompareBar from "../compareBar/CompareBar";
import SearchSidebarLoader from "../searchLoader/SearchSidebarLoader";
import SearchResultLoader from "../searchLoader/SearchResultLoader";
import ProductCard from "../productResultItem/ProductCard";
import LayoutType from "../../constants/LayoutType";
import LayoutSwitchButton from "../../components/LayoutSwitchButton/LayoutSwicthButton";
import { setCardValue, selectCardValue } from "../../redux/slices/compareSlice";
import { InformationCircleIcon } from "@rockwell-automation-inc/ra-meatball";
import { CatalogProduct, CatalogTemplate, selectUser, usePSAppSelector } from "platform-services";
import { selectActiveFilters, setActiveFilters as setActiveFiltersState } from "../../redux/slices/activeFiltersSlice";
import SearchForm from "../searchForm/SearchForm";

interface SearchSelectProps {
  _window?: () => Window;
  searchCriteriaProducts: SearchCriteriaProducts;
  searchCriteriaGroupTids: Array<CatalogProduct>;
  searchCriteriaAttributes: SearchCriteriaAttributes;
  setSearchCriteriaAttributes: Function;
  pricingAndAvailability: PricingAndAvailability;
  tids: string;
  searchCriteria: string;
  enableGuidedSelection: boolean;
  numberOfResultsPerPage: number;
  pageNumber: number;
  productsLoading: boolean;
  criteriaLoading: boolean;
  catalogTreeLoading: boolean;
  groupId?: string;
  sortOrderType?: string;
  guidedFilters: SearchProductAttributeGroup[];
  setPricingAndAvailability: (value: PricingAndAvailability) => void;
  setTids: (tids: string) => void;
  setEnableGuidedSelection: (enabled: boolean) => void;
  setPageNumber: (pageNumber: number) => void;
  setSortOrderType: (sortOrderType: string) => void;
  setGuidedFilters: (filters: SearchProductAttributeGroup[]) => void;
}

const SearchSelect = ({
  _window,
  searchCriteriaProducts,
  searchCriteriaGroupTids,
  searchCriteriaAttributes,
  pricingAndAvailability,
  setPricingAndAvailability,
  tids,
  setTids,
  searchCriteria,
  enableGuidedSelection,
  setEnableGuidedSelection,
  numberOfResultsPerPage,
  pageNumber,
  setPageNumber,
  productsLoading,
  criteriaLoading,
  catalogTreeLoading,
  groupId,
  sortOrderType,
  setSortOrderType,
  guidedFilters,
  setGuidedFilters,
}: SearchSelectProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const user = usePSAppSelector(selectUser);
  const cardValue = useSelector(selectCardValue);
  const [searchTerm, setSearchTerm] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentProductCardLayout, setCurrentProductCardLayout] = useState<string>(
    () => sessionStorage.getItem("activeCardLayout") || LayoutType.Grid
  );
  const [activeFilters, setActiveFilters] = [
    useAppSelector(selectActiveFilters),
    (x: Record<string, string[]>) => dispatch(setActiveFiltersState(x)),
  ];

  // allows drawer to work within an iframe
  const container = _window !== undefined ? () => _window().document.body : undefined;

  const drawerWidth = 350;

  useEffect(() => {
    if (cardValue === 3) {
      dispatch(setCardValue(4));
    }
  }, [cardValue, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTemplateChange = (templateId: string, templateAdd: boolean) => {
    let tidsArray = tids !== "" ? tids.split(",") : [];

    if (templateAdd) {
      tidsArray = [...tidsArray, templateId];
    } else {
      tidsArray = tidsArray.filter(tid => tid !== templateId);
    }

    setTids(tidsArray.join(","));
  };

  const handleFilterChange = (name: string, attribute: SearchProductAttribute, filterAdd: boolean) => {
    if (attribute.isGuidedSelection) {
      handleGuidedFilterChange(name, attribute, filterAdd);
      return;
    }

    const filters = { ...activeFilters };
    if (filterAdd) {
      if (Object.hasOwn(filters, name)) {
        filters[name] = [...filters[name], attribute.name];
      } else {
        filters[name] = [attribute.name];
      }
    } else {
      if (Object.hasOwn(filters, name) && filters[name].find(x => x === attribute.name)) {
        if (filters[name].length === 1) {
          delete filters[name];
        } else {
          filters[name] = filters[name].filter(x => x !== attribute.name);
        }
      }
    }
    setActiveFilters(filters);

    const label: string = filterAdd ? " chip added" : "chip deleted";
    const tabClick = new CustomEvent("chipAction", {
      detail: {
        action: "Chip Action",
        properties: {
          category: "WebApp",
          label: label,
          facet: attribute.name,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleGuidedFilterChange = (name: string, attribute: SearchProductAttribute, filterAdd: boolean) => {
    let filters = [...guidedFilters];
    let existingGroup = filters.find(x => x.name === name);
    if (filterAdd) {
      if (existingGroup) {
        existingGroup.values = [...existingGroup.values, { ...attribute, isSelected: true }];
      } else {
        let newGroup: SearchProductAttributeGroup = {
          name: name,
          values: [{ ...attribute, isSelected: true }],
        };
        filters.push(newGroup);
      }
    } else {
      if (!existingGroup) return;
      if (existingGroup.values.length === 1) {
        filters = filters.filter(x => x.name !== name);
      } else {
        existingGroup.values = existingGroup.values.filter(x => x.name !== attribute.name);
      }
    }

    setGuidedFilters(filters);
  };

  const handleChangePage = (newPageNumber: number) => {
    if (newPageNumber > 1 || newPageNumber < searchCriteriaProducts?.totalPages) {
      setPageNumber(newPageNumber);
    }
  };

  const handleChangePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
    handleChangePage(newPage);
  };

  const handleChangePageSelect = (event: SelectChangeEvent) => {
    handleChangePage(parseInt(event.target.value));
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortOrderType(event.target.value);
    if (event.target.value === "ClosestMatch") {
      const tabClick = new CustomEvent("closestMatchSortClick", {
        detail: {
          action: "Closest Match Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Closest Match",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (event.target.value === "Availability") {
      const tabClick = new CustomEvent("availabilitySortClick", {
        detail: {
          action: "Availability Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Availability",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (event.target.value === "PriceAscending") {
      const tabClick = new CustomEvent("priceAscendingSortClick", {
        detail: {
          action: "Price Ascending Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Price Ascending",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (event.target.value === "PriceDescending") {
      const tabClick = new CustomEvent("priceDescendingSortClick", {
        detail: {
          action: "Price Descending Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Price Descending",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    }
  };

  const handleChangeProductsPerPage = (event: SelectChangeEvent) => {
    dispatch(setNumberOfResultsPerPage(parseInt(event.target.value)));
    setPageNumber(1); // reset to the first page
  };

  const handleInStockDelete = () => {
    if (pricingAndAvailability.readyToShip)
      setPricingAndAvailability({
        ...pricingAndAvailability,
        readyToShip: false,
      });
    const tabClick = new CustomEvent("inStockChipDelete", {
      detail: {
        action: "Deleted In-Stock Chip",
        properties: {
          category: "WebApp",
          label: "Deleted In-Stock Chip",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleUserPriceDelete = () => {
    if (pricingAndAvailability.maxListPriceUserInputDisplay) {
      setPricingAndAvailability({
        ...pricingAndAvailability,
        maxListPriceUserInputDisplay: "",
      });
    }
    const tabClick = new CustomEvent("userEnteredPriceChipDelete", {
      detail: {
        action: "User Entered Price Chip Deleted",
        properties: {
          category: "WebApp",
          label: "User Entered Price Chip Deleted",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleLeadTimeDelete = () => {
    if (pricingAndAvailability.maxAvailability) {
      setPricingAndAvailability({
        ...pricingAndAvailability,
        maxAvailability: null,
      });
    }
    const tabClick = new CustomEvent("userEnteredLeadTimeChipDelete", {
      detail: {
        action: "User Entered Lead Time Chip Deleted",
        properties: {
          category: "WebApp",
          label: "User Entered Lead Time Chip Deleted",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  interface GroupTidChips {
    groupTemplate: CatalogProduct;
  }
  const TidChips: React.FC<GroupTidChips> = ({ groupTemplate }) => {
    return (
      <>
        {groupTemplate.childGroups.length > 1 &&
          groupTemplate.childGroups.map((childTemplate: CatalogProduct, index: number) => {
            return <TidChips key={childTemplate.title + index} groupTemplate={childTemplate} />;
          })}
        {groupTemplate.templates.length > 0 &&
          groupTemplate.templates.map((template: CatalogTemplate, index: number) => {
            if (!!tids.split(",").find(v => v === template.templateId))
              return (
                <Stack key={template.templateId} sx={{ display: "inline-grid", mb: 1, ml: 1 }} direction="row" spacing={1}>
                  <Chip
                    label={
                      <Typography sx={{ marginTop: "3px" }} color="white">
                        {template.title}
                      </Typography>
                    }
                    onDelete={event => handleTemplateChange(template.templateId, event.target.checked)}
                    color="info"
                    size="small"
                  />
                </Stack>
              );
          })}
      </>
    );
  };

  return (
    <>
      {/* TODO: fix duplicate markup */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth } }}>
        <Toolbar>
          <Typography sx={{ display: "flex", gap: 1 }}>
            <FilterIcon />
            Search Filters
          </Typography>
        </Toolbar>
        <Divider />
        <Box sx={{ py: 2, px: 3 }}>
          <SearchSidebar
            searchCriteriaGroupTids={searchCriteriaGroupTids}
            searchCriteriaAttributes={searchCriteriaAttributes}
            pricingAndAvailability={pricingAndAvailability}
            handleTemplateChange={handleTemplateChange}
            handleFilterChange={handleFilterChange}
            handlePriceAndAvailabilityChange={setPricingAndAvailability}
            tids={tids}
            setTids={setTids}
            searchCriteria={searchCriteria}
            catalogTreeLoading={catalogTreeLoading}
            groupId={groupId}
            enableGuidedSelection={enableGuidedSelection}
            setEnableGuidedSelection={setEnableGuidedSelection}
          />
        </Box>
      </Drawer>

      <Grid container spacing={3}>
        <Grid md={3} sx={{ display: { xs: "none", md: "block " } }}>
          {criteriaLoading ? (
            <SearchSidebarLoader />
          ) : (
            <SearchSidebar
              searchCriteriaGroupTids={searchCriteriaGroupTids}
              searchCriteriaAttributes={searchCriteriaAttributes}
              pricingAndAvailability={pricingAndAvailability}
              handleTemplateChange={handleTemplateChange}
              handleFilterChange={handleFilterChange}
              handlePriceAndAvailabilityChange={setPricingAndAvailability}
              tids={tids}
              setTids={setTids}
              searchCriteria={searchCriteria}
              catalogTreeLoading={catalogTreeLoading}
              groupId={groupId}
              enableGuidedSelection={enableGuidedSelection}
              setEnableGuidedSelection={setEnableGuidedSelection}
            />
          )}
        </Grid>
        <Grid xs={12} md={9}>
          <Box>
            <Container sx={{ my: 3 }} disableGutters maxWidth={false}>
              <SearchForm />
            </Container>
          </Box>
          {productsLoading ? (
            <SearchResultLoader />
          ) : (
            <>
              <Box>
                <Box sx={{ display: { md: "none" } }}>
                  <Button startIcon={<FilterIcon />} onClick={handleDrawerToggle}>
                    Search Filters
                  </Button>
                </Box>

                {searchCriteriaGroupTids
                  .filter(c => c.showCatalogGroup === true)
                  .map((template, index) => {
                    return <TidChips key={index} groupTemplate={template}></TidChips>;
                  })}

                {!criteriaLoading &&
                  searchCriteriaAttributes.productAttributes.map((group, index) => {
                    return (
                      <Fragment key={index}>
                        {group.values
                          .filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((attribute: SearchProductAttribute, index: number) => {
                            if (!attribute.isGuidedSelection && attribute.isSelected) {
                              return (
                                <Stack key={index} sx={{ display: "inline-grid", mb: 4, ml: 1 }} direction="row" spacing={1}>
                                  <Chip
                                    label={
                                      <Typography sx={{ marginTop: "3px" }} color="white">
                                        {attribute.name}
                                      </Typography>
                                    }
                                    onDelete={_ => handleFilterChange(group.name, attribute, false)}
                                    color="info"
                                    sx={{ backgroundColor: "#2a79c4" }}
                                    size="small"
                                  />
                                </Stack>
                              );
                            }
                          })}
                      </Fragment>
                    );
                  })}

                {pricingAndAvailability.readyToShip && (
                  <Stack sx={{ display: "flex", mb: 1, ml: 1 }} direction="row" spacing={1}>
                    <Chip
                      label={
                        <Typography sx={{ marginTop: "3px" }} color="white">
                          In-Stock
                        </Typography>
                      }
                      onDelete={_ => handleInStockDelete()}
                      color="info"
                      size="small"
                    />
                  </Stack>
                )}

                {pricingAndAvailability.maxListPriceUserInputDisplay === pricingAndAvailability.maxListPriceUserInputDisplay &&
                  pricingAndAvailability.maxListPriceUserInputDisplay && (
                    <Stack sx={{ display: "flex", mb: 1, ml: 1 }} direction="row" spacing={1}>
                      <Chip
                        label={
                          <Typography sx={{ marginTop: "3px" }} color="white">
                            {pricingAndAvailability.maxListPriceUserInputDisplay} List Price Maximum
                          </Typography>
                        }
                        onDelete={_ => handleUserPriceDelete()}
                        color="info"
                        size="small"
                      />
                    </Stack>
                  )}

                {pricingAndAvailability.maxAvailability && (
                  <Stack sx={{ display: "flex", mb: 1, ml: 1 }} direction="row" spacing={1}>
                    <Chip
                      label={
                        <Typography sx={{ marginTop: "3px" }} color="white">
                          {pricingAndAvailability.maxAvailability} Days Lead Time Maximum
                        </Typography>
                      }
                      onDelete={_ => handleLeadTimeDelete()}
                      color="info"
                      size="small"
                    />
                  </Stack>
                )}

                {user && enableGuidedSelection && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Typography fontWeight="bold">Location Based Results</Typography>
                    <Tooltip
                      placement="bottom"
                      title={
                        <Box
                          sx={{
                            color: theme.palette.mediumGrey.main,
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            fontSize: "12px",
                            lineHeight: "18px",
                            p: 1,
                          }}>
                          <span>
                            The most selected options offered in your location have been preselected to help you focus on what matters to you when
                            searching for our products.
                          </span>
                          <span>
                            These preselected options can be turned off by toggling off the Location Based Results filter and would allow for our
                            entire standard family product offerings to be searched on.
                          </span>
                        </Box>
                      }>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InformationCircleIcon />
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                {!criteriaLoading &&
                  searchCriteriaAttributes.productAttributes.map((group: SearchProductAttributeGroup, index: number) => {
                    return (
                      <Fragment key={index}>
                        {group.values
                          .filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((attribute, index) => {
                            if (user && enableGuidedSelection && attribute.isGuidedSelection && attribute.isSelected) {
                              return (
                                <Stack key={index} sx={{ display: "inline-grid", mb: 1, ml: 1, mt: 1 }} direction="row" spacing={1}>
                                  <Chip
                                    sx={{ background: theme.palette.purple.main }}
                                    label={
                                      <Typography sx={{ marginTop: "3px" }} color="white">
                                        {attribute.name}
                                      </Typography>
                                    }
                                    onDelete={_ => handleFilterChange(group.name, attribute, false)}
                                    color="secondary"
                                    size="small"
                                  />
                                </Stack>
                              );
                            }
                          })}
                      </Fragment>
                    );
                  })}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { xs: "left", md: "center" },
                    flexDirection: { xs: "column", md: "row" },
                    mt: 2,
                  }}>
                  {searchCriteriaProducts.totalCount > 0 && (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Showing Results:{` `}
                        {(pageNumber - 1) * numberOfResultsPerPage + 1}-
                        {(pageNumber - 1) * numberOfResultsPerPage + numberOfResultsPerPage > searchCriteriaProducts.totalCount
                          ? searchCriteriaProducts.totalCount
                          : (pageNumber - 1) * numberOfResultsPerPage + numberOfResultsPerPage}
                        {` `}/{` `}
                        {searchCriteriaProducts.totalCount.toLocaleString("US")}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        <InputLabel id="sort-select-label" sx={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: "bold" }}>
                          Sort By:
                        </InputLabel>
                        {user && (
                          <FormControl size="small">
                            <Select
                              labelId="sort-select-label"
                              id="sort-select"
                              value={sortOrderType}
                              onChange={handleSortChange}
                              MenuProps={{ sx: { position: "absolute" } }}>
                              <MenuItem value="ClosestMatch">Closest Match</MenuItem>
                              <MenuItem value="Availability">Availability</MenuItem>
                              <MenuItem value="PriceAscending">Price Ascending</MenuItem>
                              <MenuItem value="PriceDescending">Price Descending</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                        {!user && <Typography>Closest Match</Typography>}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              {/* RESULTS */}
              <LayoutSwitchButton
                currentLayout={currentProductCardLayout}
                handleLayoutChange={setCurrentProductCardLayout}
                sx={{ my: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
              />
              {searchCriteriaProducts.products && searchCriteriaProducts.products.length > 0 && (
                <Box sx={{ my: 2 }}>
                  {currentProductCardLayout === LayoutType.Grid ? (
                    <Grid container spacing={3}>
                      {searchCriteriaProducts.products.map((product, index) => (
                        <Grid key={index} sm={12} md={6} xl={3}>
                          <ProductCard
                            product={product}
                            currentLayout={currentProductCardLayout}
                            searchCriteria={searchCriteria}
                            groupId={groupId}
                            pageNumber={pageNumber}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid container rowGap={3}>
                      {searchCriteriaProducts.products.map((product, index) => (
                        <Grid key={index} sm={12}>
                          <ProductCard
                            product={product}
                            currentLayout={currentProductCardLayout}
                            searchCriteria={searchCriteria}
                            groupId={groupId}
                            pageNumber={pageNumber}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  <PageSelector
                    handleChangePageClick={(event, newPage: number) => handleChangePageClick(event, newPage)}
                    handleChangePageSelect={(event: SelectChangeEvent) => handleChangePageSelect(event)}
                    handleChangeProductsPerPage={(event: SelectChangeEvent) => handleChangeProductsPerPage(event)}
                    totalCount={searchCriteriaProducts.totalCount}
                    totalPages={searchCriteriaProducts.totalPages}
                    pageNumber={pageNumber}
                    numberOfResultsPerPage={numberOfResultsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                  />
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <CompareBar></CompareBar>
    </>
  );
};

export default SearchSelect;
