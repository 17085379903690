import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { store } from "./redux/store";
import { Theme } from "@rockwell-automation-inc/ra-meatball";
import LayoutView from "./components/layout/LayoutView";
import reportWebVitals from "./reportWebVitals";
import "./toastify.css";
import { AuthProvider, FeatureFlag, FeatureFlagProvider } from "platform-services";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/*<PersistGate loading={null} persistor={persistor}>*/}
      <ThemeProvider theme={Theme}>
        <CssBaseline enableColorScheme />
        <AuthProvider
          clientConfig={{
            clientAppName: "projects",
            axiosInstance: axios,
            options: {},
          }}>
          <FeatureFlagProvider value={window.featureFlags}>
            <LayoutView />
          </FeatureFlagProvider>
        </AuthProvider>
      </ThemeProvider>
      {/*</PersistGate>*/}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
