import { useState, useEffect } from "react";
import "./cadenas.scss";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Cadenas from "../../services/apis/Cadenas";
import CadenasResponse from "../../interfaces/CadenasResponse";
import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import DocumentItem from "../common/DocumentItem/DocumentItem";
import { DocumentType } from "../../interfaces/DocumentType";
import CadenasContent from "./CadenasContent";

interface CadenasRequestResponse {
  data: CadenasResponse;
}

interface CadenasProps {
  catalogNumber: string | undefined;
  loggedIn: boolean;
  documents: Array<any> | undefined;
}

const CadenasDisplay = ({ catalogNumber, loggedIn, documents }: CadenasProps) => {
  const [loading, setLoading] = useState(true);
  const [cadenasAvailable, setCadenasAvailable] = useState<boolean>(false);
  const [cadenasRequested, setCadenasRequested] = useState<boolean>(true);
  const [cadenasDisplayUrl, setCadenasDisplayUrl] = useState<string>("");

  // const adobeEvent = () => {
  //   const tabClick = new CustomEvent("cadenasInvoked", {
  //     detail: {
  //       action: "Cadenas Invoked",
  //       properties: {
  //         category: "WebApp",
  //         label: "Cadenas Invoked",
  //       },
  //     },
  //   });
  //   document.getElementById("root")?.dispatchEvent(tabClick);
  // };

  /* const CadenasPlaceholderContent = (
    <>
      <CardContent sx={{ display: "flex", justifyContent: "center" }}>
        {loggedIn && (
          <Button
            className="request_cadenas_button"
            onClick={() => {
              adobeEvent();
              setCadenasRequested(true);
            }}
          >
            Click Here To View And Download Drawings
          </Button>
        )}
        {!loggedIn && (
          <Button
            className="request_cadenas_button"
            onClick={() => {
              authContext.signinRedirect();
            }}
          >
            Log In To View and Download Drawings
          </Button>
        )}
      </CardContent>
    </>
  ); */

  const drawingList = documents?.filter(i => i.type === "Drawing");

  const errorMsg = () => {
    if (!cadenasAvailable && drawingList?.length === 0) {
      return (
        <>
          <Typography>Sorry, there is not an interactive 3D model or drawings available for this product.</Typography>
        </>
      );
    }
  };

  useEffect(() => {
    if (catalogNumber !== undefined) {
      const url = Cadenas.IsValidCadenasRequest(catalogNumber);
      url
        .then((response: CadenasRequestResponse) => {
          setLoading(false);
          if (response.data.error === undefined) {
            setCadenasAvailable(true);
            const displayUrl: string = Cadenas.CadenasDisplayRequest(catalogNumber);
            setCadenasDisplayUrl(displayUrl);
          }
        })
        .catch(error => {
          console.log("error in is valid cadenas request", error);
          setLoading(false);
        });

      return () => {
        //CLEAN UP TODO
      };
    }
  }, [catalogNumber]);

  // interface DocumentItemProps {
  //   document: DocumentType;
  // }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {cadenasAvailable ? (
            <Box sx={{ minWidth: 275 }}>
              {cadenasRequested && (
                <Card>
                  <CadenasContent catalogNumber={catalogNumber} cadenasDisplayUrl={cadenasDisplayUrl} loggedIn={loggedIn} />
                </Card>
              )}
            </Box>
          ) : (
            <>
              <Container maxWidth="sm">
                {drawingList !== undefined && drawingList.length > 0 && (
                  <Grid display="flex" flexDirection="column" sx={{ py: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Drawings
                    </Typography>
                    {drawingList.map((document: DocumentType, index) => {
                      return <DocumentItem key={index} document={document} />;
                    })}
                  </Grid>
                )}
              </Container>
            </>
          )}
          {errorMsg()}
        </>
      )}
    </>
  );
};

export default CadenasDisplay;
