import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";
import { Project } from "../../interfaces/Project";
import { PostProject } from "../../interfaces/PostProject";
import { PostLog } from "../../interfaces/PostLog";

class ProjectApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "PROJECTS_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
      withCredentials: true,
    };
  }

  getUserProjects() {
    const url = `${getApiUrl(this.api)}/api/Project/GetUserProjects`;
    return axios.get(url, this.config);
  }

  getProjectByGuid(id: string): Promise<AxiosResponse<Project>> {
    const url = `${getApiUrl(this.api)}/api/Project/GetUserProjectByGuid?projectGuid=${id}`;
    return axios.get<Project>(url, this.config);
  }

  getProjectById(id: number): Promise<AxiosResponse<Project>> {
    const url = `${getApiUrl(this.api)}/api/Project/GetUserProjectById?id=${id}`;
    return axios.get<Project>(url, this.config);
  }

  getLastUserOpenedProject(projectWasOpened: boolean) {
    const url = `${getApiUrl(this.api)}/api/Project/GetLastUserOpenedProject`;
    return axios.get(url, this.config);
  }

  getUser() {
    const url = `${getApiUrl(this.api)}/api/User`;
    return axios.get(url, this.config);
  }

  getWhatsNew() {
    const url = `${getApiUrl(this.api)}/api/AzureStorage/GetWhatsNew`;
    return axios.get(url, this.config);
  }

  postUser() {
    const url = `${getApiUrl(this.api)}/api/User`;
    return axios.post(url, null, this.config);
  }

  postProject(project: PostProject) {
    const url = `${getApiUrl(this.api)}/api/Project/PostProject`;
    return axios.post(url, JSON.stringify(project), this.config);
  }

  postLog(log: PostLog) {
    const url = `${getApiUrl(this.api)}/api/Log/PostLog`;
    return axios.post(url, JSON.stringify(log), this.config);
  }
}

const projectApiService = new ProjectApiService();
export default projectApiService;
