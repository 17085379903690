import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SearchProduct from "../../interfaces/SearchProduct";
import { RootState } from "../store";
import { DetailedProduct } from "../../interfaces/DetailedProduct";

interface Compare {
  value?: Array<SearchProduct>;
  parentValue: DetailedProduct | undefined;
  cardValue: number;
}

const initialState: Compare = {
  value: [],
  parentValue: undefined,
  cardValue: 4,
};

export const compare = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setCompare: (state, action: PayloadAction<Array<SearchProduct>>) => {
      state.value = action.payload;
    },
    setParent: (state, action: PayloadAction<DetailedProduct | undefined>) => {
      state.parentValue = action.payload;
    },
    setCardValue: (state, action: PayloadAction<number>) => {
      state.cardValue = action.payload;
    },
  },
});

export const { setCompare, setParent, setCardValue } = compare.actions;

export const selectCompare = (state: RootState) => state.compare.value;
export const selectParent = (state: RootState) => state.compare.parentValue;
export const selectCardValue = (state: RootState) => state.compare.cardValue;

export default compare.reducer;
