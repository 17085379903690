import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ListItem } from "@mui/material";

interface Props {
  tooltip: string;
}

const EllipsisTooltip = (props: Props) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        setIsEllipsisActive(textRef.current.scrollWidth > textRef.current.offsetWidth);
      }
    };

    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);

    return () => {
      window.removeEventListener("resize", checkEllipsis);
    };
  }, [props.tooltip]);

  //Tool tips are showing only when the text is being truncated.
  return (
    <Tooltip disableHoverListener={!isEllipsisActive} title={props.tooltip} placement="top">
      <ListItem>
        <Typography
          ref={textRef}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "250px",
          }}>
          &bull; {props.tooltip}
        </Typography>
      </ListItem>
    </Tooltip>
  );
};

export default EllipsisTooltip;
