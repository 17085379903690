import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Modal, Grid, SelectChangeEvent, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProductSelectorModal.scss";
import SearchApiService from "../../../services/apis/SearchApiService";
import SearchCriteriaProductsResponse from "../../../interfaces/SearchCriteriaProductsResponse";
import SearchCriteriaRequest from "../../../interfaces/SearchCriteriaRequest";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { setNumberOfResultsPerPage, selectNumberOfResultsPerPage } from "../../../redux/slices/numberOfResultsPerPageSlice";
import { SelectableAccessory } from "../../../interfaces/Accessory";
import QuantityAdjusterButton from "../QuantityAdjusterButton/QuantityAdjusterButton";
import PageSelector from "../PageSelector/PageSelector";
import SearchProductAttribute from "../../../interfaces/SearchProductAttribute";
import SearchSidebar from "../../searchSidebar/SearchSidebar";
import FilterIcon from "@mui/icons-material/FilterAlt";
import SearchCriteriaAttributes from "../../../interfaces/SearchCriteriaAttributes";
import SearchCriteriaTemplate from "../../../interfaces/SearchCriteriaTemplate";
import SearchCriteriaAttributesResponse from "../../../interfaces/SearchCriteriaAttributesResponse";
import { findNestedProduct } from "../../../helpers/Utils";
import { PricingAndAvailability } from "../../../interfaces/PricingAndAvailability";
import { LoadingIndicator, TeaserCard, TeaserCardContent, TeaserCardSubtitle, TeaserCardTitle } from "@rockwell-automation-inc/ra-meatball";
import { selectActiveFilters, setActiveFilters as setActiveFiltersState } from "../../../redux/slices/activeFiltersSlice";
import SearchProductAttributeGroup from "../../../interfaces/SearchProductAttributeGroup";
import { selectCatalog, usePSAppSelector, CatalogProduct } from "platform-services";

interface ProductSelectorModalProps {
  isOpen: boolean;
  searchCriteria: string;
  tids: string;
  productAccessories: SelectableAccessory[];
  accessory: SelectableAccessory;
  handleSubmit: (selectableAccessories: SelectableAccessory[]) => void;
  handleClose: () => void;
  _window?: () => Window;
}

interface PageAccessory {
  totalCount: number;
  totalPages: number;
  accessories: SelectableAccessory[];
}

const ProductSelectorModal = ({
  isOpen,
  searchCriteria,
  tids,
  productAccessories,
  accessory,
  handleSubmit,
  handleClose,
  _window,
}: ProductSelectorModalProps) => {
  const [activeFilters, setActiveFilters] = [
    useAppSelector(selectActiveFilters),
    (x: Record<string, string[]>) => dispatch(setActiveFiltersState(x)),
  ];
  const [guidedFilters, setGuidedFilters] = useState<SearchProductAttributeGroup[]>([]);

  const [_error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchCriteriaAccessories, setSearchCriteriaAccessories] = useState<PageAccessory>({
    totalCount: 0,
    totalPages: 1,
    accessories: [],
  });
  const [productsloading, setProductsLoading] = useState(true);
  const [criteriaLoading, setCriteriaLoading] = useState(true);
  const numberOfResultsPerPage = useAppSelector(selectNumberOfResultsPerPage);
  const LOCALE = "US";
  const [isSearchFiltersOpen, setIsSearchFiltersOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  const location = useLocation();

  const [searchCriteriaAttributes, setSearchCriteriaAttributes] = useState<SearchCriteriaAttributes>({
    productAttributes: [],
    userCountryName: "string",
  });

  const [pricingAndAvailability, setPricingAndAvailability] = useState<PricingAndAvailability>({
    minPrice: null,
    maxPrice: null,
    defaultMinPrice: null,
    defaultMaxPrice: null,
    minListPriceDisplay: null,
    maxListPriceDisplay: null,
    readyToShip: false,
    isReadyToShip: false,
    maxAvailability: null,
  });
  const catalog = usePSAppSelector(selectCatalog);
  const [breadcrumb, setBreadcrumb] = useState<Array<CatalogProduct>>([]);

  const handleDrawerToggle = () => {
    setIsSearchFiltersOpen(!isSearchFiltersOpen);
  };

  const parseProductSearch = (response: SearchCriteriaProductsResponse) => {
    const arr: SelectableAccessory[] = response.data.products?.map((product, index) => {
      return {
        catalogNumber: product.catalogNumber,
        description: product.description,
        templateName: product.templateId,
        id: product.id,
        quantity: productAccessories.find(i => i.catalogNumber === product.catalogNumber)?.quantity || 0,
      };
    });
    const selectableAccessoriesRef: PageAccessory = {
      totalCount: response.data.totalCount,
      totalPages: response.data.totalPages,
      accessories: arr,
    };
    setSearchCriteriaAccessories(selectableAccessoriesRef);
    setProductsLoading(false);

    return response;
    // productSearch
    //   .then((response: SearchCriteriaProductsResponse) => {

    //   })
    //   .catch((_error: Promise<any>) => {
    //     setProductsLoading(false);
    //     setError(true);
    //   });
  };

  const parsePricingAndAvailability = (response: SearchCriteriaProductsResponse) => {
    setPricingAndAvailability({
      ...pricingAndAvailability,
      ...response.data,
    });

    return response;
    // searchCriteriaProductsResponse.then(
    //   (response: SearchCriteriaProductsResponse) => {

    //   }
    // );
  };

  const onProductSearchError = (error: any) => {
    setProductsLoading(false);
    setError(true);
    return error;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const attributesFromFilters = (filters: Record<string, string[]>) => {
    return Object.keys(filters).map(
      x =>
        ({
          name: x,
          values: filters[x].map(x => ({ name: x } as SearchProductAttribute)),
        } as SearchProductAttributeGroup)
    );
  };

  const combinedFilterAttributes = () => {
    const attributes = attributesFromFilters(activeFilters);
    guidedFilters.forEach(x => {
      let existingAttr = attributes.find(a => a.name === x.name);
      if (existingAttr) {
        existingAttr.values = [...existingAttr.values, ...x.values];
      } else {
        attributes.push(x);
      }
    });
    return attributes;
  };

  useEffect(() => {
    scrollToTop();
    setProductsLoading(true);
    setCriteriaLoading(true);
    setGroupId(new URLSearchParams(location.search).get("g") || "");

    const request: SearchCriteriaRequest = {
      searchCriteria: tids,
      tids: [],
      numberOfResultsPerPage: numberOfResultsPerPage,
      locale: LOCALE,
      pageNumber: pageNumber,
      catalogGroupId: groupId,
      attributes: combinedFilterAttributes(),
      minPrice: pricingAndAvailability.minPrice,
      maxPrice: pricingAndAvailability.maxPrice,
      maxAvailability: pricingAndAvailability.maxAvailability,
      readyToShip: pricingAndAvailability.readyToShip,
    };
    //attribute search here for filters//

    SearchApiService.PostSearchCriteriaFilteredProducts(request)
      .then(parseProductSearch)
      .then(parsePricingAndAvailability)
      .catch(onProductSearchError);

    SearchApiService.PostSearchCriteriaAttributes(request).then(parseAttributeSearch).then(parseGuidedSelection).catch(onAttributeSearchError);
  }, [
    isOpen,
    numberOfResultsPerPage,
    pageNumber,
    activeFilters,
    pricingAndAvailability.minPrice,
    pricingAndAvailability.maxPrice,
    pricingAndAvailability.readyToShip,
    pricingAndAvailability.maxAvailability,
  ]);

  const getValue = (accessory: SelectableAccessory) => {
    return searchCriteriaAccessories.accessories.find(i => i.id === accessory.id)?.quantity || 0;
  };

  const handlePriceAndAvailabilityChange = (pricingAndAvailability: PricingAndAvailability) => {
    setPricingAndAvailability(pricingAndAvailability);
  };

  const handleChangeProductsPerPage = (event: SelectChangeEvent) => {
    dispatch(setNumberOfResultsPerPage(parseInt(event.target.value)));
    setPageNumber(1); // reset to the first page
  };

  const handleChangePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
    handleChangePage(newPage);
  };

  const handleChangePage = (newPageNumber: number) => {
    if (newPageNumber > 1 || newPageNumber < searchCriteriaAccessories.totalPages) {
      setPageNumber(newPageNumber);
    }
  };

  const handleChangePageSelect = (event: SelectChangeEvent) => {
    handleChangePage(parseInt(event.target.value));
  };

  const handleFilterChange = (name: string, attribute: SearchProductAttribute, filterAdd: boolean) => {
    const filters = { ...activeFilters };
    if (filterAdd) {
      if (Object.hasOwn(filters, name)) {
        filters[name] = [...filters[name], attribute.name];
      } else {
        filters[name] = [attribute.name];
      }
    } else {
      if (Object.hasOwn(filters, name) && filters[name].find(x => x === attribute.name)) {
        if (filters[name].length == 1) {
          delete filters[name];
        } else {
          filters[name] = filters[name].filter(x => x !== attribute.name);
        }
      }
    }
    setActiveFilters(filters);
  };

  const handleTemplateChange = (templateId: string, templateAdd: boolean) => {
    let tidsArray = tids !== "" ? tids.split(",") : [];

    if (templateAdd) {
      tidsArray = [...tidsArray, templateId];
    } else {
      tidsArray = tidsArray.filter(tid => tid !== templateId);
    }
  };

  const parseAttributeSearch = (response: SearchCriteriaAttributesResponse) => {
    setSearchCriteriaAttributes(response.data);
    setCriteriaLoading(false);
    return response;
  };

  const parseGuidedSelection = (response: SearchCriteriaAttributesResponse) => {
    let guidedFilters = response.data.productAttributes
      .filter(x => x.values.find(x => x.isGuidedSelection && x.isSelected))
      .map(x => ({ ...x, values: x.values.filter(x => x.isGuidedSelection && x.isSelected) } as SearchProductAttributeGroup));

    setGuidedFilters(guidedFilters);
    return response;
  };

  const onAttributeSearchError = (error: any) => {
    //don't worry, be happy
    setCriteriaLoading(false);
  };

  const handleQtyAdd = (Accessory: SelectableAccessory) => {
    const arr = searchCriteriaAccessories.accessories.map(accesoryRef => {
      if (accesoryRef.id === Accessory.id) {
        return { ...accesoryRef, quantity: accesoryRef.quantity + 1 };
      } else {
        return accesoryRef;
      }
    });
    setSearchCriteriaAccessories({
      ...searchCriteriaAccessories,
      accessories: arr,
    });
    const tabClick = new CustomEvent("quantityAdded", {
      detail: {
        action: "Quantity Increased",
        properties: {
          category: "WebApp",
          label: "Quantity Increased in Product Selector Modal",
          product: Accessory.catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleQtySubtract = (Accessory: SelectableAccessory) => {
    if (Accessory.quantity >= 1) {
      const arr = searchCriteriaAccessories.accessories.map(accesoryRef => {
        if (accesoryRef.id === Accessory.id) {
          return { ...accesoryRef, quantity: accesoryRef.quantity - 1 };
        } else {
          return accesoryRef;
        }
      });
      setSearchCriteriaAccessories({
        ...searchCriteriaAccessories,
        accessories: arr,
      });
    }
  };

  const handleQtyChange = (Accessory: SelectableAccessory, qty: number) => {
    if (qty >= 0) {
      const arr = searchCriteriaAccessories.accessories.map(accesoryRef => {
        if (accesoryRef.id === Accessory.id) {
          return { ...accesoryRef, quantity: qty };
        } else {
          return accesoryRef;
        }
      });
      setSearchCriteriaAccessories({
        ...searchCriteriaAccessories,
        accessories: arr,
      });
    }
  };

  const handleSubmitClick = (selectableAccessory: SelectableAccessory[]) => {
    const arr = selectableAccessory.map(item => {
      return {
        ...item,
        parentAccessory: accessory,
      };
    });
    handleSubmit(arr);
  };

  return (
    <Modal open={isOpen} onClose={handleClose} style={{ zIndex: 100000 }}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 950,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "solid",
            borderWidth: 2,
            borderColor: "#e5e6e9",
          }}>
          <Typography variant="h6" component="h2">
            Products
          </Typography>
          <CloseIcon sx={{ color: "#2a76c4" }} onClick={handleClose}></CloseIcon>
        </Box>
        <Grid height={500} overflow={"auto"} flexDirection={"row"} sx={{ p: 2 }}>
          <Grid flexDirection={"column"}>
            {!isSearchFiltersOpen && (
              <Button startIcon={<FilterIcon />} onClick={handleDrawerToggle} sx={{ mb: 1 }}>
                Search Filters
              </Button>
            )}

            {isSearchFiltersOpen && (
              <Button onClick={handleDrawerToggle} sx={{ mb: 1, ml: 1 }}>
                Product Selection
              </Button>
            )}

            {/* TODO: fix duplicate markup */}
            {criteriaLoading && isSearchFiltersOpen && <LoadingIndicator />}
            {!criteriaLoading && isSearchFiltersOpen && (
              <Box>
                <SearchSidebar
                  searchCriteriaGroupTids={[]}
                  searchCriteriaAttributes={searchCriteriaAttributes}
                  handleTemplateChange={handleTemplateChange}
                  handleFilterChange={handleFilterChange}
                  tids={tids}
                  searchCriteria={searchCriteria}
                  handlePriceAndAvailabilityChange={handlePriceAndAvailabilityChange}
                  pricingAndAvailability={pricingAndAvailability}
                  catalogTreeLoading={false}
                />
              </Box>
            )}
            {productsloading && !isSearchFiltersOpen && <LoadingIndicator />}

            {!isSearchFiltersOpen &&
              !productsloading &&
              searchCriteriaAccessories.accessories.map((product: SelectableAccessory, index) => {
                const isSubtractDisabled = () => {
                  const quantity = productAccessories.find(i => i.id === product.id)?.quantity;
                  if (quantity && quantity <= 1) {
                    return true;
                  }
                  if (!quantity && product.quantity <= 1) {
                    return true;
                  } else {
                    return false;
                  }
                };

                return (
                  <Grid key={index}>
                    <TeaserCard
                      hover={false}
                      raVariant="horizontal-outlined"
                      sx={{ width: "100%", height: "125px", mb: 2 }}
                      style={{ justifyContent: "space-between" }}>
                      <TeaserCardContent style={{ maxWidth: "400px" }}>
                        <TeaserCardTitle>{product.catalogNumber}</TeaserCardTitle>
                        <TeaserCardSubtitle
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}>
                          {product.description}
                        </TeaserCardSubtitle>
                      </TeaserCardContent>
                      <Box display="flex" flexDirection="column" alignContent="flex-end" justifyContent="center" sx={{ px: 2 }}>
                        <QuantityAdjusterButton
                          value={getValue(product)}
                          handleQtyAdd={() => handleQtyAdd(product)}
                          handleQtyChange={e => handleQtyChange(product, Number(e))}
                          handleQtySubtract={() => handleQtySubtract(product)}
                          subtractDisabled={isSubtractDisabled()}></QuantityAdjusterButton>
                      </Box>
                    </TeaserCard>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>

        {!isSearchFiltersOpen && (
          <>
            <Box>
              <PageSelector
                handleChangePageClick={(event: React.ChangeEvent<unknown>, newPage: number) => handleChangePageClick(event, newPage)}
                handleChangePageSelect={(event: SelectChangeEvent) => handleChangePageSelect(event)}
                handleChangeProductsPerPage={(event: SelectChangeEvent) => handleChangeProductsPerPage(event)}
                totalCount={searchCriteriaAccessories.totalCount}
                totalPages={searchCriteriaAccessories.totalPages}
                pageNumber={pageNumber}
                numberOfResultsPerPage={numberOfResultsPerPage}
                rowsPerPageOptions={[25, 50, 100]}></PageSelector>
            </Box>
            <Box sx={{ width: "100%", justifyContent: "flex-end" }}>
              <Button sx={{ ml: "auto" }} onClick={() => handleSubmitClick(searchCriteriaAccessories.accessories.filter(i => i.quantity > 0))}>
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProductSelectorModal;
