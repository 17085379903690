import { AxiosRequestConfig } from "axios";

import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";

class CadenasApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    };
  }

  IsValidCadenasRequest(catalogNumber: string) {
    var axiosWithoutdefaultHeaders = axios.create();
    this.api = "PART_COMMUNITY_API";
    //these headers need to be removed for parts community call to work
    delete axiosWithoutdefaultHeaders.defaults.headers.common["Access-Control-Allow-Origin"];
    delete axiosWithoutdefaultHeaders.defaults.headers.common["Access-Control-Allow-Credentials"];
    delete axiosWithoutdefaultHeaders.defaults.headers.common["Access-Control-Allow-Methods"];
    const url = getApiUrl(this.api) + catalogNumber;

    return axiosWithoutdefaultHeaders.get(url);
  }

  CadenasDisplayRequest(catalogNumber: string) {
    this.api = "DISPLAY_CADENAS_URL";

    const url = getApiUrl(this.api) + catalogNumber;

    return url;
  }
}

export default new CadenasApiService();
