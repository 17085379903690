import { Box, Typography } from "@mui/material";
import { MissingIcon } from "@rockwell-automation-inc/ra-meatball";
import MuiDrawer from "@mui/material/Drawer";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PARAMS from "../../constants/PARAMS";
import { hostAppRemoveCover } from "../../helpers/hostAppHTMLModifier";

type Anchor = "left" | "right";

interface ErrorMessageProps {
  drawerPosition: Anchor;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { drawerPosition } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [errorDrawerOpen, setErrorDrawerOpen] = useState<boolean>(true);
  const cid = new URLSearchParams(location.search).get(PARAMS.CID);
  const configurator = new URLSearchParams(location.search).get(PARAMS.CONFIGURATOR);

  const handleClick = () => {
    const searchParams = new URLSearchParams(location.search);
    if (cid) {
      searchParams.delete(PARAMS.CID);
    }
    if (configurator) {
      searchParams.delete(PARAMS.CONFIGURATOR);
    }
    setErrorDrawerOpen(false);
    hostAppRemoveCover();
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <MuiDrawer
      anchor={drawerPosition}
      open={errorDrawerOpen}
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "4px",
          padding: "24px 24px 32px 24px",
          minWidth: "65vw",
        },
      }}>
      <Box sx={{ margin: "auto", textAlign: "center" }}>
        <MissingIcon sx={{ height: "500px", width: "500px", color: "grey" }}></MissingIcon>
        <Typography>We're sorry ! Your configuration session seems to have timed out.</Typography>
        <Typography component="a" onClick={() => handleClick()} sx={{ color: "primary.main", textDecoration: "underline", cursor: "pointer" }}>
          Please return and Try Again.
        </Typography>
      </Box>
    </MuiDrawer>
  );
};

export default ErrorMessage;
