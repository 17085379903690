import { Box, Container, Grid, List, ListItem, ListItemButton, ListItemText, Link as MuiLink, Typography } from "@mui/material";
import {
  TeaserCard,
  TeaserCardContent,
  TeaserCardDescription,
  TeaserCardMedia,
  TeaserCardSubtitle,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import { selectUser, useAuthContext, useFeatureFlagContext, usePSAppSelector } from "platform-services";
import { useEffect, useState } from "react";
import Masthead from "../../components/masthead/Masthead";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import config from "../../config";
import systemDesigners from "../../configFiles/system-designer-cards.json";
import FEATURES from "../../constants/FeatureFlags";
import { useAppSelector } from "../../redux/hooks";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import { GetURLQueryVariable } from "../../util/GetURLQueryVariable";
import toast from "../../util/toast";

export interface IDesignerCard {
  type: string;
  imageUrl: string;
  familyHeader: string;
  familySubheader: string;
  description: string;
  actionText: string;
  baseUrl: string;
  path: string;
  business: string;
  isFeatureFlagged: boolean;
  featureFlagName: string;
  groupName: string;
}

const SystemDesigners = () => {
  const featureFlagContext = useFeatureFlagContext();
  const [designerCards, setDesignerCards] = useState<Record<string, IDesignerCard[]>>({});
  const [designerCardsGroupingName, setDesignerCardsGroupingName] = useState<string[]>([]);
  interface TagProps {
    text: string;
  }
  const authContext = useAuthContext();
  const [selectedGroupname, setSelectedGroupname] = useState("All");
  useEffect(() => {
    const designerCardsData: IDesignerCard[] = systemDesigners
      .filter(designer => {
        if (designer.isFeatureFlagged && designer.featureFlagName) {
          return window.featureFlags && window.featureFlags[designer.featureFlagName as keyof typeof window.featureFlags] === "true";
        }
        return true;
      })
      .sort((a: IDesignerCard, b: IDesignerCard) => a.groupName.localeCompare(b.groupName));
    const systemDesignerGrouping = groupBy(designerCardsData, designerCardsData => designerCardsData?.groupName);
    const valueSystemDesigner = Object.keys(systemDesignerGrouping).map(designerName => systemDesignerGrouping[designerName]);
    const updatedDesignerCardsData: IDesignerCard[] = [];
    valueSystemDesigner &&
      valueSystemDesigner?.forEach(innerArray => {
        innerArray &&
          innerArray?.forEach(element => {
            updatedDesignerCardsData.push(element);
          });
      });

    setDesignerCardsGroupingName(Object.keys(systemDesignerGrouping).map(designerName => designerName));
    if (selectedGroupname === "All") {
      setDesignerCards(systemDesignerGrouping);
    } else {
      setDesignerCards({ [selectedGroupname]: systemDesignerGrouping[selectedGroupname] });
    }
  }, [window.featureFlags, selectedGroupname]);

  const user = usePSAppSelector(selectUser);
  const project = useAppSelector(selectSelProject);

  let configureNote = "";

  if (!user) {
    configureNote = "Log In to Configure this component ";
  } else if (!project) {
    configureNote = "Create/Select a Project to Configure this component";
  }

  const Tag: React.FC<TagProps> = ({ text }) => {
    const borderColor = "#ffffff";
    const backgroundColor = "linear-gradient(83deg, #00AEEF 37.75%, #003E7E 88.65%)";

    return (
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "0px",
          backgroundImage: backgroundColor,

          px: 3,
          "&::before": {
            content: '""', // Unicode for a checkbox
            position: "absolute",
            bottom: "0",
            right: "0",
            borderBottom: "25px solid #ffffff",
            borderLeft: "12px solid transparent",
            width: 0,
          },
        }}>
        <Typography fontWeight="normal" sx={{ color: borderColor, textTransform: "Capitalize" }}>
          {text}
        </Typography>
      </Box>
    );
  };

  const groupBy = <T, K extends keyof any>(array: T[], key: (item: T) => K): Record<K, T[]> => {
    return array.reduce((result, currentItem) => {
      const group = key(currentItem);
      if (!result[group]) {
        result[group] = [];
      }
      result[group].push(currentItem);
      return result;
    }, {} as Record<K, T[]>);
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number, grpname: string) => {
    setSelectedGroupname(grpname);
  };
  const handleGroupName = (grpValue: string) => {
    setSelectedGroupname(grpValue);
  };

  const handleNavigate = (e: any, designer: IDesignerCard) => {
    if (!user) {
      toast.warn(
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>Sign in is required</Typography>
          <MuiLink sx={{ mr: 2 }} onClick={() => authContext.signinRedirect()}>
            Link
          </MuiLink>
        </Box>
      );
      // toast.warn(`Sign in is required`);
    } else {
      const route = `${config[designer.baseUrl as keyof typeof config]}${designer.path}?projectGuid=${GetURLQueryVariable(
        "projectGuid"
      )}&projectId=${GetURLQueryVariable("projectId")}`;

      window.location.href = route;
    }
  };

  const getEnabledBusinesses = (designerIteration: IDesignerCard[]) => {
    if (featureFlagContext.canDisplay(FEATURES.ENABLED_BUSINESSES)) {
      const business = JSON.parse(window.env["ENABLED_BUSINESSES"]);
      return designerIteration.filter(i => (i.business !== "" ? business.flat().includes(i.business) : true));
    } else {
      return designerIteration;
    }
  };

  const getImageUrl = (path: string) => {
    if (path.includes("https")) {
      return path;
    }
    return `${config.DOCUMENT_HOST_LOCATION}${path}`;
  };

  const getDesignerListing = (designerIteration: IDesignerCard[]) => {
    return getEnabledBusinesses(designerIteration).map(designer => {
      return (
        <Grid item>
          <TeaserCard
            sx={{
              ml: "auto",
              mr: "auto",
              width: "350px",
              cursor: "pointer",
              height: 575,
              py: 2,
              px: 1,
              position: "relative",
            }}
            onClick={e => handleNavigate(e, designer)}
            raVariant="vertical"
            hover={false}>
            <Tag text={designer.type} />
            <TeaserCardMedia image={getImageUrl(designer.imageUrl)} sx={{ mt: 4, mx: 4 }} style={{ backgroundSize: "contain" }} />
            <TeaserCardContent style={{ paddingBottom: "0px" }}>
              <TeaserCardSubtitle sx={{ textAlign: "center", minHeight: "19px" }}>{designer.familySubheader}</TeaserCardSubtitle>
              <TeaserCardTitle
                variant="h6"
                fontWeight="bold"
                textAlign="center"
                sx={{
                  fontSize: "1.2rem",
                  mb: 4,
                }}>
                {designer.familyHeader}
              </TeaserCardTitle>
              <TeaserCardDescription sx={{ minHeight: "125px" }}>{designer.description}</TeaserCardDescription>
            </TeaserCardContent>
          </TeaserCard>
        </Grid>
      );
    });
  };

  // Output: <a href="https://www.example.com">Click here</a>

  return (
    <>
      <Masthead />

      <Container sx={{ my: 0, py: 3 }}>
        <SearchBreadcrumbs loading={false} systemDesigner selectedGroupname={selectedGroupname} handleGroupName={handleGroupName} />
      </Container>
      <Container></Container>

      <Container sx={{ mb: 0, mt: 0, pt: 0, pl: 0, pr: 0, display: "flex", direction: "column" }} style={{ backgroundColor: "white" }}>
        <Grid width={"20%"} md={3} pr={3} ml={4}>
          <Box>
            <Typography>{<h1>{"System Designers"}</h1>}</Typography>
            <List sx={{ display: "flex", flexDirection: "column", justifyContent: "left", width: "100% " }}>
              {designerCardsGroupingName.length !== 0 &&
                ["All"].concat(designerCardsGroupingName).map((grpname, index) => (
                  <ListItem key={grpname} disablePadding>
                    <ListItemButton
                      sx={{
                        borderBottom: "1px solid rgba(0,0,0,.2)",
                        borderRadius: grpname === selectedGroupname ? 1 : 0,
                        pt: 1,
                        transistion: "none",
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                          border: "1px solid #003E7E ",
                        },
                        "&.Mui-focusVisible": {
                          backgroundColor: "transparent",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "transparent",
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        p: 1,
                      }}
                      selected={grpname === selectedGroupname}
                      onClick={event => handleListItemClick(event, index, grpname)}>
                      <ListItemText id={grpname} primaryTypographyProps={{ style: { fontWeight: "bold", fontSize: "1.1rem" } }}>
                        {grpname}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Grid>

        <Grid container py={9} md={9} spacing={2}>
          {Object.keys(designerCards).map(designer => {
            return (
              <>
                <Container sx={{ p: 3 }} disableGutters>
                  <h2>{designer}</h2>
                </Container>
                {getDesignerListing(designerCards[designer])}
              </>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export default SystemDesigners;
