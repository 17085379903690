import { Box, ButtonBase, CardMedia, FormControlLabel, Typography } from "@mui/material";
import { TeaserCard } from "@rockwell-automation-inc/ra-meatball";
import { IAttribute } from "../interfaces/IAttribute";
import defaultImage from "../../assets/Image_Unavailable.svg";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import QuantityAdjusterButton from "../../../common/QuantityAdjusterButton/QuantityAdjusterButton";
import { documentUrl } from "../../../../helpers/Utils";

interface AttributeCardProps {
  attribute: IAttribute;
  selectedAttribute: IAttribute;
  handleCardSelect: Function;
  qauntityAdd: Function;
  qauntitySubtract: Function;
  disabled: boolean;
  allowedCount: number;
  isSelectedCount: number;
  selectorArrayHasImage: boolean;
  greatestDisplayTextLength: number;
}

const AttributeCard = (props: AttributeCardProps) => {
  const {
    attribute,
    selectedAttribute,
    handleCardSelect,
    qauntityAdd,
    qauntitySubtract,
    disabled,
    allowedCount,
    isSelectedCount,
    selectorArrayHasImage,
    greatestDisplayTextLength,
  } = props;
  const [imageSrc, setImageSrc] = useState(documentUrl(attribute.thumbnail));
  const [hasQuantity, setHasQuantity] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [showCardOptionOne, setShowCardOptionOne] = useState<boolean>(false);
  const [showCardOptionTwo, setShowCardOptionTwo] = useState<boolean>(false);

  const handleSelectClick = (event: any) => {
    if (hasQuantity || (disabled && selectedAttribute.class !== "Set")) {
      event.stopPropagation();
    } else {
      attribute.isSelected = !attribute.isSelected;
      handleCardSelect(attribute, false);
    }
  };

  const handleSelectClickSpecial = (event: any) => {
    attribute.isSelected = !attribute.isSelected;
    attribute.userModAttribute = true;
    handleCardSelect(attribute, true);
  };

  const handleImageError = () => {
    setImageSrc(defaultImage);
  };

  const handleQtyAdd = () => {
    qauntityAdd(attribute);
  };

  const handleQtyChange = (qty: Number) => {
    // NO USE FOR THIS FUNCTION. NEED TO DECLARE TO USE COMPONENT
  };

  const handleQtySubtract = () => {
    qauntitySubtract(attribute);
  };

  const isSubtractDisabled = () => {
    if (attribute.qty && attribute.qty === 0) {
      return true;
    }
    if (!attribute.qty && attribute.qty === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const value: boolean =
      ((attribute?.isShowing ?? false) && (attribute?.isAllowed ?? false) && selectedAttribute.class !== "Set") ||
      ((attribute?.isShowing ?? false) && (attribute?.isAllowed ?? false) && selectedAttribute.class === "Set" && hasQuantity);
    setShowCardOptionOne(value);
  }, [attribute, selectedAttribute, hasQuantity]);

  useEffect(() => {
    const value: boolean = (attribute?.isShowing ?? false) && selectedAttribute?.class === "Set" && !hasQuantity;
    setShowCardOptionTwo(value);
  }, [attribute, selectedAttribute, hasQuantity]);

  useEffect(() => {
    if (typeof attribute.qty === "number") {
      if (attribute.qty > 0) {
        attribute.isSelected = true;
      } else {
        attribute.isSelected = false;
      }
      setQuantity(attribute.qty);
      setHasQuantity(true);
    }
  }, [attribute]);

  const card = () => {
    return (
      <TeaserCard
        raVariant="vertical"
        hover={false}
        sx={{
          flexShrink: "0",
          flexWrap: "wrap",
          fontFamily: "inherit",
          borderRadius: "4px",
          width: "100%",
          border: attribute?.isSelected ? "solid 2px #003E7E" : "",
          ":hover": {
            boxShadow: "2px 2px 10px 2px #00000040",
          },
          display: "flex",
          flexDirection: "row",
          padding: "5px",
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            minHeight: greatestDisplayTextLength > 50 ? "66px" : "36px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "5px",
          }}>
          {selectedAttribute.class === "Set" && !hasQuantity && (
            <FormControlLabel
              sx={{ margin: "0px" }}
              key={attribute.id}
              control={<Checkbox value={attribute.id} disabled={attribute.isAllowed ? false : true} checked={attribute.isSelected} />}
              label=""></FormControlLabel>
          )}
          {selectorArrayHasImage && (
            <Box sx={{ width: "80px", height: "80px", flexShrink: "0", visibility: attribute.thumbnail ? "" : "hidden" }}>
              <CardMedia
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                component="img"
                image={imageSrc}
                onError={handleImageError}
                alt="component_img"
              />
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flex: "1 0 0 ", width: "100%" }}>
            <Typography
              sx={{
                height: "auto",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Barlow !important",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "18px",
                textAlign: "left",
                width: "100%",
              }}>
              {attribute.displayText}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {hasQuantity && (
            <Box sx={{ paddingTop: "5px", paddingBottom: "5px", width: "50%" }}>
              <QuantityAdjusterButton
                value={quantity}
                handleQtyAdd={() => handleQtyAdd()}
                handleQtyChange={e => handleQtyChange(Number(e))}
                handleQtySubtract={() => handleQtySubtract()}
                subtractDisabled={isSubtractDisabled()}
              />
            </Box>
          )}
        </Box>
        {/* <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", padding: "2px", gap: "2px", alignItems: "center", borderRadius: "4px", border: "1px solid #2A79C4" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <g clip-path="url(#clip0_13373_4335)">
                      <path d="M6 0C2.69175 0 0 2.69138 0 6C0 9.30863 2.69175 12 6 12C9.30825 12 12 9.30863 12 6C12 2.69138 9.30825 0 6 0ZM6 11.3798C3.03338 11.3798 0.619875 8.96663 0.619875 6C0.619875 3.03338 3.03338 0.62025 6 0.62025C8.96663 0.62025 11.3798 3.03338 11.3798 6C11.3798 8.96663 8.96663 11.3798 6 11.3798Z" fill="#235D9F" />
                      <path d="M6 1.90163C5.92066 1.90172 5.84461 1.93328 5.78851 1.98938C5.73241 2.04548 5.70085 2.12154 5.70075 2.20088V6.32662L3.0375 7.91175C2.98164 7.94563 2.93837 7.99682 2.91426 8.05753C2.89015 8.11825 2.88652 8.18518 2.90391 8.24815C2.92131 8.31112 2.95879 8.36669 3.01065 8.40641C3.06251 8.44614 3.12592 8.46785 3.19125 8.46825C3.24498 8.46824 3.29771 8.45373 3.34387 8.42625L6.15338 6.7545C6.19776 6.72779 6.23451 6.69008 6.26007 6.64502C6.28562 6.59995 6.29912 6.54906 6.29925 6.49725V2.20088C6.29905 2.12157 6.26746 2.04557 6.21138 1.98949C6.15531 1.93341 6.07931 1.90182 6 1.90163Z" fill="#235D9F" />
                    </g>
                    <defs>
                      <clipPath id="clip0_13373_4335">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Typography sx={{ color: "#2A79C4", fontFamily: "Barlow !important", fontSize: "12px", fontStyle: "normal", fontWeight: "400", lineHeight: "16px" }}>Est. Lead Time</Typography>
                </Box>
                <ButtonBase sx={{ display: "flex", padding: "2px", gap: "2px", alignItems: "center", borderRadius: "4px", border: "1px solid #2A79C4", background: "#2A79C4" }}>
                  <Typography sx={{ color: "#FFF", fontFamily: "Barlow !important", fontSize: "12px", fontStyle: "normal", fontWeight: "500", lineHeight: "16px" }}>CONFIGURE</Typography>
                </ButtonBase>
              </Box>
            </Box> */}
      </TeaserCard>
    );
  };

  return (
    <>
      {showCardOptionOne && (
        <ButtonBase
          sx={{ width: "50%", gap: "5px", padding: "5px", minHeight: "46px" }}
          onClick={e =>
            (selectedAttribute.class !== "Set" && allowedCount === 1 && isSelectedCount === 0) || attribute.userModAttribute
              ? handleSelectClickSpecial(e)
              : handleSelectClick(e)
          }>
          {card()}
        </ButtonBase>
      )}
      {showCardOptionTwo && (
        <ButtonBase
          sx={{ width: "50%", gap: "5px", padding: "5px", minHeight: "46px" }}
          onClick={e => handleSelectClick(e)}
          disabled={!attribute?.isAllowed}>
          {card()}
        </ButtonBase>
      )}
    </>
  );
};

export default AttributeCard;
