import { Skeleton } from "@mui/material";

const SearchResultLoader = () => {
  return (
    <>
      {[...Array(3)].map((value: any, index: number) => (
        <Skeleton key={index} variant="rounded" sx={{ width: "100%", height: "84px", my: 2 }} />
      ))}
    </>
  );
};

export default SearchResultLoader;
