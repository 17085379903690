import { Box, BoxProps } from "@mui/material";

interface ImageProps {
  alt: string;
  src: string;
  loading?: "eager" | "lazy";
}

export const Image = (props: BoxProps & ImageProps) => (
  <Box
    component="img"
    {...props}
    sx={{
      maxWidth: "100%",
      height: "auto",
    }}
  />
);
