import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ProjectBom } from "../../interfaces/ProposalWorks";
import { Project } from "../../interfaces/Project";
import { BOM } from "../../interfaces/BOM";

interface SelBOM {
  value?: BOM | null;
}

const initialState: SelBOM = {
  value: null,
};

export const bom = createSlice({
  name: "selectedProject",
  initialState,
  reducers: {
    setSelBOM: (state, action: PayloadAction<BOM | null>) => {
      state.value = action.payload;
    },
  },
});

export const { setSelBOM } = bom.actions;

export const selectSelBOM = (state: RootState) => state.bom.value;

export default bom.reducer;
