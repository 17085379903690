import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";
import { ProductAssistant } from "../../interfaces/ProductAssistant";
import { AxiosRequestConfig } from "axios";

class ProductAssistantApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "RAISE_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
      withCredentials: true,
    };
  }

  ProductAssistant(data: ProductAssistant) {
    const url = `${getApiUrl(this.api)}/ProductAssistant`;
    return axios.post(url, data, this.config);
  }
}

const productassistantApiService = new ProductAssistantApiService();
export default productassistantApiService;
