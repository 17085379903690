import { useEffect, useState } from "react";
import AttributeValueSearch from "../attributeValueSearch/AttributeValueSearch";
import { Box, Typography } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../interfaces/IAttribute";
import AttributeCard from "./AttributeCard";
import AttributeTextInput from "./AttributeTextInput";

interface AttributeSelectorProps {
  selectedAttribute: IAttribute;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  updateConfigurator: (attributes: IAttribute[] | undefined, attribute: IAttribute) => void;
  updateConfiguratorWithUserEnteredValue: (attribute: IAttribute, value: string) => void;
  setIsContinueButtonDisabled: any;
  groupAttributeIndex: number;
  quantityAdd: Function;
  quantitySubtract: Function;
}

const AttributeSelector = ({
  selectedAttribute,
  configFromTemplateDataFromParent,
  setIsContinueButtonDisabled,
  groupAttributeIndex,
  updateConfigurator,
  updateConfiguratorWithUserEnteredValue,
  quantityAdd,
  quantitySubtract,
}: AttributeSelectorProps) => {
  const [attribute, setAttribute] = useState<IAttribute>();
  const [selectorArray, setSelectorArray] = useState<IAttribute[]>();
  const [configData, setConfigData] = useState<IConfiguratorFromTemplateResponse>();
  const [selectorHasImage, setSelectorHasImage] = useState<boolean>(false);
  const [searchHasResults, setSearchHasResults] = useState<boolean>(true);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const showSearchBarDefaultValue: number = 20;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectorArrayAllowedCount, setSelectorArrayAllowedCount] = useState<number>(0);
  const [selectorArrayIsSelectedCount, setSelectorArrayIsSelectedCount] = useState<number>(0);
  const [greatestDisplayTextLength, setGreatestDisplayTextLength] = useState<number>(0);

  const createArrayFromAttributeString = (attributesString: string) => {
    let attributeStringArray;
    if (attributesString) {
      attributeStringArray = attributesString.split(",");
    }
    const attributeChildArray: IAttribute[] = [];
    if (attributeStringArray) {
      attributeStringArray.forEach((atr: string) => {
        if (configFromTemplateDataFromParent?.attributes) {
          for (let i = 0; i < configFromTemplateDataFromParent?.attributes?.length; i++) {
            if (atr === configFromTemplateDataFromParent.attributes[i].id) {
              configFromTemplateDataFromParent.attributes[i].isShowing = true;
              attributeChildArray.push(configFromTemplateDataFromParent.attributes[i]);
              break;
            }
          }
        }
      });
    }
    const uniqueItemMap = new Map();
    attributeChildArray.forEach((child: IAttribute) => {
      if (!uniqueItemMap.has(child.displayText)) {
        uniqueItemMap.set(child.pidCode, child);
      }
    });
    const uniqueItems = Array.from(uniqueItemMap.values());
    setSelectorArray(attributeChildArray);
  };

  const hasAttributeSelected = () => {
    if (selectorArray?.length) {
      for (const selector of selectorArray) {
        if (selector.isSelected) {
          return true;
        }
      }
    } else {
      if (
        (selectedAttribute?.class === "String" && selectedAttribute?.value) ||
        (selectedAttribute?.class === "Real" && selectedAttribute?.value) ||
        (selectedAttribute.class === "Integer" && selectedAttribute.value)
      ) {
        return true;
      }
      return false;
    }
  };

  const handleCardSelect = (atr: IAttribute, isSpecial: boolean) => {
    const updatedArray = selectorArray?.map(selector => {
      return {
        ...selector,
        isSelected: atr.id === selector.id && !atr.isSelected,
        userModAttribute: atr.id === selector.id && isSpecial,
      };
    });
    updateConfigurator(undefined, atr);
    setSelectorArray(updatedArray);
  };

  const resetIsShowing = () => {
    const updatedArray = selectorArray?.map(selector => {
      return { ...selector, isShowing: true };
    });
    setSearchHasResults(true);
    setSelectorArray(updatedArray);
  };

  const normalize = (value: string) => {
    return value.replaceAll(" ", "").toLowerCase();
  };

  const handleSearchSubmit = (query: string) => {
    const normalizedQuery = normalize(query);
    const updatedArray = selectorArray?.map(selector => {
      return {
        ...selector,
        isShowing: normalize(selector.displayText).includes(normalizedQuery),
      };
    });
    setSearchHasResults((updatedArray?.length ?? 0) > 0);
    setSelectorArray(updatedArray);
  };

  const hasImage = () => {
    if (selectorArray) {
      let hasImage = false;
      for (const attribute of selectorArray) {
        if (attribute.thumbnail) {
          hasImage = true;
          break;
        }
      }
      setSelectorHasImage(hasImage);
    }
  };
  const displayTextLength = () => {
    if (selectorArray) {
      let stringLength = 0;
      for (const attribute of selectorArray) {
        if (attribute.displayText.length > stringLength) {
          stringLength = attribute.displayText.length;
        }
      }
      setGreatestDisplayTextLength(stringLength);
    }
  };

  const setCountsAndConditionals = () => {
    if (selectorArray) {
      let allowedCount: number = 0;
      let isSelectedCount: number = 0;
      for (const attribute of selectorArray) {
        if (attribute.isAllowed === true) {
          allowedCount += 1;
        }
        if (attribute.isSelected === true) {
          isSelectedCount += 1;
        }
      }
      setSelectorArrayIsSelectedCount(isSelectedCount);
      setSelectorArrayAllowedCount(allowedCount);
      allowedCount > 1 ? setDisabled(false) : setDisabled(true);
      setShowSearchBar(selectorArray && allowedCount > showSearchBarDefaultValue ? true : false);
    }
  };
  useEffect(() => {
    hasImage();
    displayTextLength();
    setCountsAndConditionals();
  }, [selectorArray]);

  useEffect(() => {
    setConfigData(configFromTemplateDataFromParent);
  }, []);

  useEffect(() => {
    createArrayFromAttributeString(selectedAttribute.range);
    setAttribute(selectedAttribute);
  }, [selectedAttribute]);

  useEffect(() => {
    createArrayFromAttributeString(selectedAttribute.range);
  }, [configFromTemplateDataFromParent]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}>
        <Typography
          sx={{
            fontFamily: "Barlow !important",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "24px",
          }}>
          {attribute?.displayText}
        </Typography>
        {hasAttributeSelected() && <CheckCircleOutlineOutlinedIcon sx={{ width: "24px", height: "24px", color: "#12850A" }} />}
        {!hasAttributeSelected() && <WarningAmberOutlinedIcon sx={{ width: "24px", height: "24px", color: "#FFCC00" }} />}
      </Box>
      {showSearchBar && (
        <AttributeValueSearch
          handleAttributeSearchSubmit={handleSearchSubmit}
          resetIsShowing={resetIsShowing}
          selectedAttribute={selectedAttribute}
        />
      )}
      <Box
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "75%",
          overflow: "auto",
        }}>
        {selectedAttribute.range && selectorArray && searchHasResults ? (
          <>
            {selectorArray.map(attribute => {
              return (
                <AttributeCard
                  key={attribute.id}
                  attribute={attribute}
                  selectedAttribute={selectedAttribute}
                  handleCardSelect={handleCardSelect}
                  qauntityAdd={quantityAdd}
                  qauntitySubtract={quantitySubtract}
                  disabled={disabled}
                  allowedCount={selectorArrayAllowedCount}
                  isSelectedCount={selectorArrayIsSelectedCount}
                  selectorArrayHasImage={selectorHasImage}
                  greatestDisplayTextLength={greatestDisplayTextLength}
                />
              );
            })}
          </>
        ) : (
          <>
            {(attribute?.class === "String" || attribute?.class === "Real" || attribute?.class === "Integer") && (
              <AttributeTextInput
                selectedAttribute={selectedAttribute}
                updateConfiguratorWithUserEnteredValue={updateConfiguratorWithUserEnteredValue}></AttributeTextInput>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AttributeSelector;
