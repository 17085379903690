import { AxiosRequestConfig } from "axios";

import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";

class ProductApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "RAISE_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
      withCredentials: true,
    };
  }

  GetProduct(catalogNumber: string, locale: string) {
    const url = `${getApiUrl(this.api)}/Product/GetProduct?catalogNumber=${catalogNumber}&locale=${locale}`;

    return axios.get(url);
  }

  GetDetailedProduct(catalogNumber: string, sourceType: string) {
    const url = `${getApiUrl(this.api)}/Product/GetDetailedProduct?catalogNumber=${catalogNumber}&type=${sourceType}`;

    return axios.get(url, this.config);
  }

  GetProducts(tid: string, locale: string) {
    const url = `${getApiUrl(this.api)}/Product/GetProducts?tid=${tid}&locale=${locale}`;

    return axios.get(url);
  }

  GetShorterLeadTimeProducts(catalogNumber: string, locale: string) {
    const url = `${getApiUrl(this.api)}/Product/GetShorterLeadTimeProducts?catalogNumber=${catalogNumber}&locale=${locale}`;

    return axios.get(url);
  }

  GetAlternativeProducts(catalogNumber: string, locale: string) {
    const url = `${getApiUrl(this.api)}/Product/GetAlternativeProducts?catalogNumber=${catalogNumber}&locale=${locale}`;

    return axios.get(url);
  }

  GetProductComponents(catalogNumber: string) {
    const url = `${getApiUrl(this.api)}/Product/GetProductComponents?catalogNumber=${catalogNumber}`;

    return axios.get(url);
  }

  PostProductAttributeComparisons(catalogNumbers: string[]) {
    var formatedCatalogNumbers = {
      catalogNumbers: catalogNumbers,
    };

    const url = `${getApiUrl(this.api)}/Product/PostProductAttributeComparisons`;

    return axios.post(url, formatedCatalogNumbers);
  }

  PostSearchProductAttributeComparisons(catalogNumbers: string[]) {
    var formatedCatalogNumbers = {
      catalogNumbers: catalogNumbers,
    };

    const url = `${getApiUrl(this.api)}/Product/PostSearchProductAttributeComparisons`;

    return axios.post(url, formatedCatalogNumbers);
  }
}

const productApiService = new ProductApiService();
export default productApiService;
