import { Box, Container, Typography } from "@mui/material";
import SearchForm from "../searchForm/SearchForm";

const SearchFormBanner = () => {
  return (
    <Box
      sx={{
        py: 3,
        background: "linear-gradient(90deg, rgba(0,62,126,1) 0%, rgba(141,1,52,1) 100%)",
      }}>
      <Container>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ mr: 8 }}>
            <Typography variant="h3" component="h1" color="white !important">
              Advisor
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <SearchForm />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SearchFormBanner;
