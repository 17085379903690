import { Box, ButtonBase, DialogTitle, Typography } from "@mui/material";
import { CloseIcon } from "@rockwell-automation-inc/ra-meatball";
import ProductRealTimeData from "../productRealTimeData/ProductRealTimeData";
import ConfiguratorSummary from "../configurationSummary/ConfigurationSummary";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../interfaces/IAttribute";
import { useConfiguratorData } from "../../contexts/ConfiguratorDataContext";

interface ConfiguratorLayoutProps {
  isValidConfiguration: boolean;
  isLoading: boolean;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  handleConfiguratorDrawerClose: () => void;
  updateConfigurator: (attributes: IAttribute[] | undefined, attribute: IAttribute) => void;
  updateConfiguratorWithUserEnteredValue: (attribute: IAttribute, value: string) => void;
  resetConfiguration: () => void;
  quantityAdd: Function;
  quantitySubtract: Function;
}

const ConfiguratorLayout = (props: ConfiguratorLayoutProps) => {
  const {
    isValidConfiguration,
    isLoading,
    configFromTemplateDataFromParent,
    handleConfiguratorDrawerClose,
    updateConfigurator,
    updateConfiguratorWithUserEnteredValue,
    resetConfiguration,
    quantityAdd,
    quantitySubtract,
  } = props;

  const { isViewSummaryVisible, setIsViewSummaryVisible } = useConfiguratorData();

  const handleClick = () => {
    if (isViewSummaryVisible) {
      setIsViewSummaryVisible(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "60vw",
          height: "95vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
        onClick={handleClick}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", padding: "0px" }}>
          <Typography sx={{ fontFamily: "Barlow !important", fontWeight: "500", fontSize: "24px", lineHeight: "28px" }}>Configurator</Typography>
          <ButtonBase onClick={() => handleConfiguratorDrawerClose()}>
            <CloseIcon />
          </ButtonBase>
        </DialogTitle>

        <ProductRealTimeData
          configFromTemplateDataFromParent={configFromTemplateDataFromParent}
          resetConfiguration={resetConfiguration}></ProductRealTimeData>
        <ConfiguratorSummary
          configFromTemplateDataFromParent={configFromTemplateDataFromParent}
          updateConfigurator={updateConfigurator}
          updateConfiguratorWithUserEnteredValue={updateConfiguratorWithUserEnteredValue}
          handleConfiguratorDrawerClose={handleConfiguratorDrawerClose}
          isValidConfiguration={isValidConfiguration}
          quantityAdd={quantityAdd}
          quantitySubtract={quantitySubtract}></ConfiguratorSummary>
      </Box>
    </>
  );
};

export default ConfiguratorLayout;
