import { SelectableAccessory } from "../interfaces/Accessory";
import { BOMItemThumbprint } from "../interfaces/BOMItemThumbprint";
import { DetailedProduct } from "../interfaces/DetailedProduct";

export const getConfigBOMItemThumbPrint = (product: DetailedProduct) => {
  const configThumbprint: BOMItemThumbprint = {
    Type: product.sourceType,
    CatalogNumber: product.catalogNumber,
    Description: product.description,
    ListPrice: product.listPrice,
    EstimatedLeadTime: product.estimatedLeadTime?.toString(),
    Upc: product.upc,
    PartNumber: product.partNumber,
    Weight: product.weight,
    Dimensions: product.dimensions,
    ProdLifeCycleStatus: product.prodLifeCycleStatus,
    StockStatus: product.stockStatusDisplay,
    Version: "1.0.0",
  };
  return JSON.stringify(configThumbprint);
};

export const getConfigBOMItemAccessoryThumbPrint = (accessory: SelectableAccessory) => {
  const configThumbprint: BOMItemThumbprint = {
    Type: "unknown",
    CatalogNumber: accessory.catalogNumber,
    Description: accessory.description,
    Version: "1.0.0",
  };
  return JSON.stringify(configThumbprint);
};
