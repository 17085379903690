import { Box, Button, Unstable_Grid2 as Grid, SvgIcon, SvgIconProps, Typography } from "@mui/material";
import { DownloadIcon } from "@rockwell-automation-inc/ra-meatball";
import { documentUrl } from "../../../helpers/Utils";
import { DocumentType } from "../../../interfaces/DocumentType";

interface DocumentItemProps {
  document: DocumentType;
}

const getURL = (urlLink: string): string => {
  if (urlLink.includes("cutsheet")) {
    return "https://configurator.rockwellautomation.com/api/Product/" + urlLink;
  } else if (urlLink.includes("/")) {
    return urlLink;
  } else {
    return documentUrl(urlLink);
  }
};

const handleRedirect = (urlLink: string) => {
  window.open(getURL(urlLink), "_blank");
};

const viewOnline = (doc: any) => {
  const tabClick = new CustomEvent("documentsViewOnline", {
    detail: {
      action: "Documents View Online Click",
      properties: {
        category: "WebApp",
        label: "Documents View Online Click",
        docTitle: doc.title,
      },
    },
  });
  document.getElementById("root")?.dispatchEvent(tabClick);
};

const downloadDoc = (doc: any) => {
  const tabClick = new CustomEvent("documentsDownload", {
    detail: {
      action: "Documents Download Click",
      properties: {
        category: "WebApp",
        label: "Documents Download Click",
        docTitle: doc.title,
      },
    },
  });
  document.getElementById("root")?.dispatchEvent(tabClick);
};

function saveFile(fileName: string, urlLink: string) {
  // Get file name from url.
  var name = fileName.substring(fileName.lastIndexOf("/") + 1).split("?")[0];
  var xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = function () {
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    a.download = name;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  xhr.open("GET", urlLink.replace(/http:|https:/, window.location.protocol));
  xhr.send();
}

const handleSaveFile = async (urlLink: string) => {
  const url = getURL(urlLink);
  saveFile(urlLink, url);
};

const DocumentItem = (props: DocumentItemProps) => {
  const { document } = props;

  const DocumentIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1130_6107)">
            <path d="M18.3944 4.13353L14.9153 0.378018C14.8143 0.26924 14.6717 0.207335 14.5223 0.207398H3.06846C2.77414 0.207054 2.53526 0.442378 2.53491 0.733006C2.53491 0.733213 2.53491 0.733425 2.53491 0.733631V19.6818C2.53491 19.9718 2.77352 20.2074 3.06846 20.2074H18.0014C18.2955 20.2074 18.5342 19.9723 18.5349 19.6818V4.48851C18.5351 4.35673 18.4849 4.22973 18.3944 4.1329V4.13353ZM17.5855 4.71976H14.9248V1.77735L17.5855 4.64913V4.71976ZM3.48428 19.2693V1.14487H13.9754V5.18849C13.9754 5.44723 14.1874 5.65723 14.4501 5.65723H17.5855V19.2693H3.48428Z" />
            <path d="M14.9708 7.09991H5.09912C4.85318 7.09991 4.65381 7.30978 4.65381 7.56866C4.65381 7.82755 4.85318 8.03741 5.09912 8.03741H14.9708C15.2167 8.03741 15.4161 7.82755 15.4161 7.56866C15.4161 7.30978 15.2167 7.09991 14.9708 7.09991ZM14.9708 10.9293H5.09912C4.85318 10.9293 4.65381 11.1392 4.65381 11.398C4.65381 11.6569 4.85318 11.8668 5.09912 11.8668H14.9708C15.2167 11.8668 15.4161 11.6569 15.4161 11.398C15.4161 11.1392 15.2167 10.9293 14.9708 10.9293ZM14.9708 14.758H5.09912C4.85318 14.758 4.65381 14.9679 4.65381 15.2268C4.65381 15.4857 4.85318 15.6955 5.09912 15.6955H14.9708C15.2167 15.6955 15.4161 15.4857 15.4161 15.2268C15.4161 14.9679 15.2167 14.758 14.9708 14.758Z" />
          </g>
          <defs>
            <clipPath id="clip0_1130_6107">
              <rect width="20" height="20" fill="white" transform="translate(0.534912 0.207397)" />
            </clipPath>
          </defs>
        </svg>
      </SvgIcon>
    );
  };

  const ExternalLinkIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.2 17.0602C26.0946 17.0587 25.9899 17.0784 25.8921 17.1181C25.7944 17.1578 25.7056 17.2167 25.631 17.2912C25.5565 17.3658 25.4976 17.4546 25.4579 17.5523C25.4182 17.6501 25.3985 17.7548 25.4 17.8602V27.7602C25.3924 28.1291 25.2424 28.4808 24.9815 28.7417C24.7206 29.0026 24.3689 29.1526 24 29.1602H4.30002C3.93112 29.1526 3.57946 29.0026 3.31855 28.7417C3.05764 28.4808 2.90769 28.1291 2.90002 27.7602V8.26023C2.8955 8.07514 2.92862 7.89107 2.99737 7.71917C3.06613 7.54726 3.16909 7.39112 3.3 7.26021C3.43092 7.12929 3.58706 7.02633 3.75896 6.95758C3.93086 6.88882 4.11494 6.8557 4.30002 6.86023H14.7C14.8055 6.86171 14.9102 6.84203 15.0079 6.80235C15.1056 6.76267 15.1944 6.7038 15.269 6.62921C15.3436 6.55463 15.4025 6.46585 15.4421 6.36812C15.4818 6.27039 15.5015 6.1657 15.5 6.06023C15.5099 5.95271 15.4959 5.84435 15.4592 5.74282C15.4225 5.64129 15.3639 5.54908 15.2875 5.47274C15.2112 5.39639 15.119 5.33778 15.0174 5.30105C14.9159 5.26433 14.8075 5.25039 14.7 5.26023H4.30002C3.5309 5.26023 2.79327 5.56576 2.24941 6.10962C1.70556 6.65348 1.40002 7.3911 1.40002 8.16023V27.6602C1.40002 28.4294 1.70556 29.167 2.24941 29.7108C2.79327 30.2547 3.5309 30.5602 4.30002 30.5602H24C24.7692 30.5602 25.5068 30.2547 26.0506 29.7108C26.5945 29.167 26.9 28.4294 26.9 27.6602V17.8602C26.9223 17.7592 26.9206 17.6544 26.8949 17.5541C26.8693 17.4539 26.8204 17.3611 26.7523 17.2833C26.6841 17.2054 26.5987 17.1447 26.5027 17.1059C26.4068 17.0672 26.3031 17.0515 26.2 17.0602Z" />
          <path d="M30.4 2.36023C30.3965 2.30834 30.3743 2.25949 30.3375 2.22271C30.3008 2.18594 30.2519 2.16374 30.2 2.16023L19.4 1.86023C19.3 1.86023 19.3 1.86023 19.2 1.96023L17.8 3.46023C17.7766 3.47763 17.7577 3.50026 17.7446 3.5263C17.7315 3.55235 17.7247 3.58109 17.7247 3.61023C17.7247 3.63937 17.7315 3.66811 17.7446 3.69416C17.7577 3.7202 17.7766 3.74283 17.8 3.76023C17.8035 3.81212 17.8257 3.86097 17.8625 3.89775C17.8993 3.93452 17.9481 3.95672 18 3.96023L27.3 4.06023L13.1 18.1602C13.0457 18.2141 13.0105 18.2844 13 18.3602C13 18.4602 13 18.4602 13.1 18.5602L14.2 19.6602C14.2539 19.7146 14.3242 19.7497 14.4 19.7602C14.5 19.7602 14.5 19.7602 14.6 19.6602L28.6 5.66023V14.8602C28.6035 14.9121 28.6257 14.961 28.6625 14.9977C28.6993 15.0345 28.7481 15.0567 28.8 15.0602C28.8543 15.067 28.9094 15.0615 28.9613 15.0442C29.0133 15.0269 29.0606 14.9982 29.1 14.9602L30.5 13.5602C30.5544 13.5063 30.5895 13.4361 30.6 13.3602L30.4 2.36023Z" />
        </svg>
      </SvgIcon>
    );
  };

  return (
    <>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "8px 16px",
          borderBottom: "1px solid #D9D9D9",
        }}>
        <Box display="flex" alignItems="center" gap="16px">
          <DocumentIcon
            sx={{
              width: "49px",
              height: "49px",
            }}
          />
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>{document.title}</Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end" gap="12px">
          <Button
            startIcon={<DownloadIcon />}
            variant="link"
            onClick={() => {
              handleSaveFile(document.formats[0].pages[0].urlLink);
              downloadDoc(document);
            }}>
            {document.formats[0].format}
          </Button>
          <Button
            startIcon={<ExternalLinkIcon />}
            variant="link"
            onClick={() => {
              handleRedirect(document.formats[0].pages[0].urlLink);
              viewOnline(document);
            }}>
            View Online
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default DocumentItem;
