import MuiDrawer from "@mui/material/Drawer";
import ConfiguratorLayout from "../configuratorLayout/ConfiguratorLayout";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../interfaces/IAttribute";

type Anchor = "left" | "right";

interface ConfiguratorDrawerProps {
  drawerPosition: Anchor;
  setOpenDrawer: boolean;
  isValidConfiguration: boolean;
  isLoading: boolean;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  handleConfiguratorDrawerClose: () => void;
  updateConfigurator: (attributes: IAttribute[] | undefined, attribute: IAttribute) => void;
  updateConfiguratorWithUserEnteredValue: (attribute: IAttribute, value: string) => void;
  resetConfiguration: () => void;
  quantityAdd: Function;
  quantitySubtract: Function;
}

const Drawer = ({
  drawerPosition,
  setOpenDrawer,
  isValidConfiguration,
  isLoading,
  configFromTemplateDataFromParent,
  handleConfiguratorDrawerClose,
  updateConfigurator,
  updateConfiguratorWithUserEnteredValue,
  resetConfiguration,
  quantityAdd,
  quantitySubtract,
}: ConfiguratorDrawerProps) => {
  return (
    <>
      <MuiDrawer
        anchor={drawerPosition}
        open={setOpenDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "4px",
            padding: "24px 24px 32px 24px",
            minWidth: "65vw",
          },
        }}>
        <ConfiguratorLayout
          isLoading={isLoading}
          handleConfiguratorDrawerClose={handleConfiguratorDrawerClose}
          isValidConfiguration={isValidConfiguration}
          configFromTemplateDataFromParent={configFromTemplateDataFromParent}
          updateConfigurator={updateConfigurator}
          updateConfiguratorWithUserEnteredValue={updateConfiguratorWithUserEnteredValue}
          resetConfiguration={resetConfiguration}
          quantityAdd={quantityAdd}
          quantitySubtract={quantitySubtract}
        />
      </MuiDrawer>
    </>
  );
};

export default Drawer;
