export enum LogSourceType {
  Unknown = "Unknown",
  AdvisorSearch = "AdvisorSearch",
  AdvisorProject = "AdvisorProject",
  AdvisorControlSystem = "AdvisorControlSystem",
  AdvisorBom = "AdvisorBom",
  AdvisorPowerControl = "AdvisorPowerControl",
  AdvisorPDP = "AdvisorPDP",
  Configurator = "Configurator",
  ConfiguratorPDP = "ConfiguratorPDP",
  ReleaseProcess = "ReleaseProcess",
}
