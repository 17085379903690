import { FormGroup, Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface QuantityAdjusterButtonProps {
  value: string | number;
  handleQtySubtract: () => void;
  handleQtyAdd: () => void;
  handleQtyChange: (e: string) => void;
  subtractDisabled?: boolean;
}

const QuantityAdjusterButton: React.FC<QuantityAdjusterButtonProps> = ({
  value,
  handleQtyAdd,
  handleQtyChange,
  handleQtySubtract,
  subtractDisabled,
}) => {
  return (
    <>
      <FormGroup
        row
        sx={{
          border: "1px solid #E5E6E9",
          borderRadius: "4px",
          p: "4px",
          flexWrap: "nowrap",
          width: "158px",
          maxWidth: "100%",
        }}>
        <Button
          variant="outlined"
          onClick={() => handleQtySubtract()}
          disabled={subtractDisabled}
          sx={{
            // px: 0,
            border: 0,
            borderRadius: 0,
            bgcolor: "#E5E6E9",
            color: "#6D6E71",
            minWidth: 0,
            p: 0,
            width: "32px",
            height: "32px",
            "&.Mui-disabled": {
              color: "#6D6E71",
            },
            "&:hover": {
              transform: "none",
              bgcolor: "#E5E6E9",
            },
          }}>
          <RemoveIcon />
        </Button>
        <TextField
          variant="outlined"
          placeholder="Qty"
          inputProps={{
            style: { textAlign: "center", height: "100%" },
          }}
          style={{ width: "3rem" }}
          value={value}
          type="number"
          onChange={e => handleQtyChange(e.target.value)}
          sx={{
            border: 0,
            flexGrow: 1,
            height: "20px",
            alignSelf: "center",
            input: {
              p: 0,
            },
            fieldset: { border: 0 },
          }}
        />
        <Button
          variant="outlined"
          onClick={() => handleQtyAdd()}
          sx={{
            p: 0,
            border: 0,
            borderRadius: 0,
            bgcolor: "#E5E6E9",
            color: "#6D6E71",
            minWidth: 0,
            width: "32px",
            height: "32px",
            "&.Mui-disabled": {
              color: "#6D6E71",
            },
            "&:hover": {
              transform: "none",
              bgcolor: "#E5E6E9",
            },
          }}>
          <AddIcon />
        </Button>
      </FormGroup>
    </>
  );
};

export default QuantityAdjusterButton;
