import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

interface Preferences {
  lineVoltage?: string | undefined;
  lineFrequency?: string | undefined;
  controlVoltage?: string | undefined;
  enclosureType?: string | undefined;
  controlSystem?: string | undefined;
  networkType?: string | undefined;
  controlSoftware?: string | undefined;
  safetyLevel?: string | undefined;
  isoStandard?: string | undefined;
  industrySpecific?: string | undefined;
}

const initialState: Preferences = {};

export const preferences = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setLineVoltage: (state, action: PayloadAction<string>) => {
      state.lineVoltage = action.payload;
    },
    setLineFrequency: (state, action: PayloadAction<string>) => {
      state.lineFrequency = action.payload;
    },
    // TODO
  },
});

export const {
  setLineVoltage,
  setLineFrequency,
  // TODO
} = preferences.actions;

export const selectPreferences = (state: RootState) => state.preferences;

export default preferences.reducer;
