import { Unstable_Grid2 as Grid, CardMedia, ButtonBase, Typography, Container } from "@mui/material";
import { LoadingIndicator, TeaserCard, TeaserCardContent, TeaserCardTitle } from "@rockwell-automation-inc/ra-meatball";
import { Link } from "react-router-dom";
import ROUTES from "../../constants/Routes";
import { CatalogProduct } from "platform-services";
import { documentUrl } from "../../helpers/Utils";

interface SearchCategoriesProps {
  searchCriteria: string;
  searchCriteriaGroupTids: Array<CatalogProduct>;
  productsLoading: boolean;
  catalogTreeLoading: boolean;
}

const SearchCategories = (props: SearchCategoriesProps) => {
  const { searchCriteria, searchCriteriaGroupTids, productsLoading, catalogTreeLoading } = props;

  return (
    <Grid container spacing={3}>
      {productsLoading || catalogTreeLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Grid sm={12}>
            <Container maxWidth="md">
              <Typography sx={{ fontSize: "1.25em" }}>Please select a category of products to refine your search:</Typography>
            </Container>
          </Grid>
          {searchCriteriaGroupTids.map((catalog, index) => {
            return (
              <Grid key={index} sm={6} md={4} lg={3}>
                <ButtonBase
                  component={Link}
                  to={`${ROUTES.SEARCH}?s=${searchCriteria}&g=${catalog.catalogGroupId}`}
                  sx={{ width: "100%", textAlign: "center" }}>
                  <TeaserCard
                    raVariant="vertical-outlined"
                    key={index}
                    sx={{
                      display: "flex",
                      height: "275px",
                      width: "100%",
                    }}
                    variant="elevation">
                    {catalog.photo && (
                      <CardMedia
                        component="img"
                        sx={{
                          width: "100%",
                          maxHeight: "150px",
                          backgroundSize: "contain",
                          objectFit: "contain",
                        }}
                        image={documentUrl(catalog.photo)}
                        alt=""
                      />
                    )}

                    <TeaserCardContent>
                      <TeaserCardTitle component="h4" variant="h6">
                        {catalog.title}
                      </TeaserCardTitle>
                    </TeaserCardContent>
                  </TeaserCard>
                </ButtonBase>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default SearchCategories;
