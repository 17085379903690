import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, Unstable_Grid2 as Grid, Typography } from "@mui/material";
import ProductApiService from "../../services/apis/ProductApiService";
import SearchForm from "../../components/searchForm/SearchForm";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import Response from "../../services/apis/Response";
import { SelectableAccessory } from "../../interfaces/Accessory";
import Masthead from "../../components/masthead/Masthead";
import { useDispatch, useSelector } from "react-redux";
import { ProductTabs } from "../../components/productTabs/ProductTabs";
import QuantityAdjusterButton from "../../components/common/QuantityAdjusterButton/QuantityAdjusterButton";
import ProductMetadata from "../../components/productMetadata/ProductMetadata";
import { Image } from "../../components/common/Image/Image";
import ProductBomButton from "../../components/productBomButton/ProductBomButton";
import { useAppSelector } from "../../redux/hooks";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import { documentUrl, findNestedProduct } from "../../helpers/Utils";
import { setParent } from "../../redux/slices/compareSlice";
import toast from "../../util/toast";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import {
  BOMApiService,
  BOMItemApiService,
  CatalogProduct,
  CatalogTemplate,
  FeatureFlag,
  selectCatalog,
  selectUser,
  usePSAppSelector,
} from "platform-services";
import { PostBOMItem } from "../../interfaces/PostBOMItem";
import { BOM } from "../../interfaces/BOM";
import { selectSelBOM, setSelBOM } from "../../redux/slices/selectedBOMSlice";
import { PostBOM } from "../../interfaces/PostBOM";
import { getConfigBOMItemAccessoryThumbPrint, getConfigBOMItemThumbPrint } from "../../util/ConfigBOMItemThumbprint";
import ConfiguratorButton from "../../components/rockwellConfigurator/components/configuratorButton/ConfiguratorButton";
import FEATURES from "../../constants/FeatureFlags";

interface ProductResponse {
  data: DetailedProduct;
}

const Product = () => {
  const dispatch = useDispatch();
  const user = usePSAppSelector(selectUser);
  const selProject = useSelector(selectSelProject);
  const PAGES = {
    BOM_SELECT: "BOM_SELECT",
    BOM_ACTIONS: "BOM_ACTIONS",
  };
  const [currentPage, setCurrentPage] = useState<(typeof PAGES)[keyof typeof PAGES]>(PAGES.BOM_SELECT);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [catalogNumber, setCatalogNumber] = useState("");
  const [sourceType, setSourceType] = useState("");
  const LOCALE = "US";
  const [product, setProduct] = useState<DetailedProduct>();
  const [selectedAccessories, setSelectedAccessories] = useState<SelectableAccessory[]>([]);
  const [qty, setQty] = useState(1);
  const location = useLocation();
  const [searchCriteria, setSearchCriteria] = useState<string | null>();
  const [groupId, setGroupId] = useState<string | null>();
  const catalog = usePSAppSelector(selectCatalog);
  const [currentProduct, setCurrentProduct] = useState<CatalogProduct>();
  const [breadcrumb, setBreadcrumb] = useState<Array<CatalogProduct>>([]);
  const [catalogGroupId, setCatalogGroupId] = useState<string>();
  const [template, setTemplate] = useState<CatalogTemplate>();
  const [loading, setLoading] = useState(true);
  const selBOM = useSelector(selectSelBOM);
  const buildBreadcrumbsForTemplate = (productList: Array<CatalogProduct>, templateId: string) => {
    productList.forEach(p => {
      if (p.childGroups.length > 0) {
        buildBreadcrumbsForTemplate(p.childGroups, templateId);
      }

      if (p.templates.length > 0) {
        p.templates.forEach(t => {
          if (t.templateId === templateId) {
            setCatalogGroupId(p.catalogGroupId);
            setTemplate(t);
          }
        });
      }
    });
  };

  const loadCurrentProduct = () => {
    setLoading(true);
    setBreadcrumb([]);

    if (!catalogGroupId) {
      setCurrentProduct(undefined);
    }

    if (catalog && catalog.length > 0) {
      setLoading(false);
    }

    if (catalogGroupId && catalog && catalog?.length > 0) {
      const product: CatalogProduct | undefined = findNestedProduct(catalog, catalogGroupId, setBreadcrumb);
      setCurrentProduct(product);
      setLoading(false);
    }
  };

  const qtyAdd = () => {
    const tabClick = new CustomEvent("quantityAdded", {
      detail: {
        action: "Increased Quantity",
        properties: {
          category: "WebApp",
          label: "Increased Quantity from Product Summary page",
          product: product?.catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  useEffect(
    () => {
      if (catalog && product?.templateId) {
        buildBreadcrumbsForTemplate(catalog, product.templateId);
      }
      loadCurrentProduct();
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [catalog, product, catalogGroupId]
  );

  useEffect(() => {
    setCatalogNumber(new URLSearchParams(location.search).get("catalogNumber") || "");
    setSourceType(new URLSearchParams(location.search).get("type") || "");

    setSearchCriteria(new URLSearchParams(location.search).get("s"));
    setGroupId(new URLSearchParams(location.search).get("g"));
  }, [location.search]);

  useEffect(
    () => {
      if (catalogNumber === "") {
        return;
      }

      const product = ProductApiService.GetDetailedProduct(catalogNumber, sourceType);
      product
        .then((response: ProductResponse) => {
          setProduct(response.data);
          dispatch(setParent(response.data));
        })
        .catch(error => {
          // TODO
          setLoading(false);
          console.log(error);
        });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogNumber]
  );

  const createNewBOM = async () => {
    let bom: BOM;
    if (selProject?.guid !== undefined) {
      const postBom: PostBOM = {
        name: "DEFAULT BOM",
        projectId: selProject?.guid,
      };
      try {
        const result = await BOMApiService.postBOM(postBom);
        const response = new Response(result);
        if (response.isSuccessful()) {
          dispatch(setSelBOM(response.getData()));
          bom = response.getData();
          return bom;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const addProductToSelectedBOM = async (product: DetailedProduct, newBOM?: BOM | undefined) => {
    setIsLoading(true);
    const savingToast = toast.loading("Saving...");

    const bomItem: PostBOMItem = {
      name: product.catalogNumber,
      description: product.description,
      listPrice: product.listPrice,
      photo: product.photo,
      productType: "Product",
      quantity: qty,
      configThumbprint: getConfigBOMItemThumbPrint(product),
      sourceType: product.sourceType,
      children: selectedAccessories.map(accessory => {
        return {
          name: accessory.catalogNumber,
          description: accessory.description,
          templateTitle: accessory.templateName,
          quantity: accessory.quantity,
          sourceType: "RaiseExactMatchCatalogNumber",
          children: [],
          configThumbprint: getConfigBOMItemAccessoryThumbPrint(accessory),
        };
      }),
    };

    try {
      if (newBOM) {
        const result = await BOMItemApiService.postBOMItem(newBOM.id, bomItem);

        const response = new Response(result);

        if (response.isSuccessful()) {
          setCurrentPage(PAGES.BOM_ACTIONS);
          toast.loadedSuccess(savingToast, `${product.catalogNumber} successfully added to "${selProject?.name}"`);
        }
      }
      if (newBOM === undefined && selBOM) {
        const result = await BOMItemApiService.postBOMItem(selBOM.id, bomItem);

        const response = new Response(result);

        if (response.isSuccessful()) {
          setCurrentPage(PAGES.BOM_ACTIONS);
          toast.loadedSuccess(savingToast, `${product.catalogNumber} successfully added to "${selProject?.name}"`);
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.dismiss(savingToast);
      toast.error(
        <>
          <div>
            Unable to add {product.catalogNumber} to {selProject?.name}.
          </div>
          <div>
            The selected Project has reached the maximum number of items that can be added. Please contact product support for help, if needed.
          </div>
        </>
      );
    }
    setIsLoading(false);
    const addToBom = new CustomEvent("trackAddToBom", {
      detail: {
        action: "Add To Bom",
        properties: {
          category: "WebApp",
          label: bomItem,
          productQty: qty,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(addToBom);
  };

  const handleAddToBom = async () => {
    if (!product) return;
    if (selBOM === null) {
      createNewBOM().then((newBOM: BOM | undefined) => {
        addProductToSelectedBOM(product, newBOM);
      });
    } else {
      addProductToSelectedBOM(product);
    }
  };

  const handleQuickAdd = async (/* e: any */) => {
    setIsLoading(true);
    handleAddToBom();
    setIsLoading(false);
    setIsOpen(true);
    setCurrentPage(PAGES.BOM_ACTIONS);
    const tabClick = new CustomEvent("trackAddToBom", {
      detail: {
        action: "Product Add to Bom from Product Summary",
        properties: {
          category: "WebApp",
          label: "Product Add to Bom from Product Summary",
          product: catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleClose = () => {
    setCurrentPage(PAGES.BOM_SELECT);
    setIsOpen(false);
  };

  return (
    <>
      <Masthead />

      <Container sx={{ my: 0, py: 3 }}>
        <SearchBreadcrumbs
          loading={loading}
          searchCriteriaGroupTids={breadcrumb}
          currentProduct={currentProduct}
          template={template}
          catalogNumber={product?.catalogNumber}
          searchCriteria={searchCriteria}
          groupId={groupId}
        />
      </Container>

      <Box sx={{ py: 1, width: "60%", mx: "auto" }}>
        <Container sx={{ my: 3 }}>
          <SearchForm />
        </Container>
      </Box>

      <Container sx={{ my: 0, py: 3, px: 4 }} style={{ backgroundColor: "white", borderRadius: "4px" }}>
        <Grid container justifyContent="space-between" direction="row" sx={{ mt: 4, mb: 1, gap: 1 }}>
          {product && (
            <>
              <Grid container md={7} lg={8} spacing={2}>
                {product?.photo && (
                  <Grid md={3}>
                    <Image alt="" src={documentUrl(product.photo)} width="200px" />
                  </Grid>
                )}
                <Grid md={product?.photo ? 9 : 12}>
                  <Typography variant="h5" component="h1" fontWeight="bold" sx={{ mb: 2 }}>
                    {product?.catalogNumber}
                  </Typography>
                  <Typography>{product?.description}</Typography>
                </Grid>
              </Grid>

              <Grid container direction="column" alignContent={{ sm: "start", md: "flex-end" }} md={3} lg={2} gap={2}>
                <ProductBomButton
                  isLoading={isLoading}
                  setIsOpen={setIsOpen}
                  handleQuickAdd={handleQuickAdd}
                  user={user}
                  setCurrentPage={setCurrentPage}
                />
                <FeatureFlag feature={FEATURES.PI26_BP02_CONFIGURATOR_ENABLED}>
                  {template?.configurationType === "True" && (
                    <ConfiguratorButton key={0} templateID={template?.templateId} productID={product.catalogNumber} />
                  )}
                </FeatureFlag>
                {user && <ProductMetadata product={product} />}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      sm: "flex-start",
                      md: "flex-end",
                    },
                  }}>
                  <QuantityAdjusterButton
                    value={qty}
                    handleQtyChange={() => {
                      setQty(qty);
                    }}
                    handleQtySubtract={() => {
                      qty > 1 && setQty(qty - 1);
                    }}
                    handleQtyAdd={() => {
                      setQty(qty + 1);
                      qtyAdd();
                    }}
                    subtractDisabled={qty <= 1}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <ProductTabs product={product} setSelectedAccessories={setSelectedAccessories} user={user} />
      </Container>
      {/* <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <BomDialogContent
          LOCALE={LOCALE}
          handleCancel={handleClose}
          handleClose={handleClose}
          product={product}
          qty={qty}
          PAGES={PAGES}
          billOfMaterials={billOfMaterials}
          setBillOfMaterials={setBillOfMaterials}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedAccessories={selectedAccessories}
        />
      </Dialog> */}
    </>
  );
};

export default Product;
