const hostAppApplyCover = () => {
  const mainElement: HTMLElement | null = document.querySelector("main");

  const applyCover = (element: HTMLElement) => {
    const overlay = document.createElement("div");

    overlay.style.position = "absolute";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    overlay.style.backgroundColor = "white";
    overlay.style.zIndex = "1000";
    overlay.classList.add("custom-overlay");

    const style = window.getComputedStyle(element);

    if (style.position === "static") {
      element.style.position = "relative";
    }
    element.appendChild(overlay);
  };
  if (mainElement) {
    applyCover(mainElement);
  }
};

const hostAppRemoveCover = () => {
  const overlays = document.querySelectorAll(".custom-overlay");

  overlays.forEach(overlay => {
    if (overlay.parentElement) {
      overlay.parentElement.removeChild(overlay);
    }
  });
};

export { hostAppApplyCover, hostAppRemoveCover };
