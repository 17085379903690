import { useEffect, useLayoutEffect, useState } from "react";
import { Box, Container, Alert } from "@mui/material";
import Masthead from "../../components/masthead/Masthead";
import SearchForm from "../../components/searchForm/SearchForm";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import SearchCategories from "../../components/searchCategories/SearchCategories";
import SearchSelect from "../../components/searchSelect/SearchSelect";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import searchApiService from "../../services/apis/SearchApiService";
import SearchCriteriaRequest from "../../interfaces/SearchCriteriaRequest";
import { selectNumberOfResultsPerPage } from "../../redux/slices/numberOfResultsPerPageSlice";
import { PricingAndAvailability } from "../../interfaces/PricingAndAvailability";
import SearchCriteriaProductsResponse from "../../interfaces/SearchCriteriaProductsResponse";
import ROUTES from "../../constants/Routes";
import SearchCriteriaProducts from "../../interfaces/SearchCriteriaProducts";
import SearchApiService from "../../services/apis/SearchApiService";
import SearchCriteriaAttributesResponse from "../../interfaces/SearchCriteriaAttributesResponse";
import SearchCriteriaAttributes from "../../interfaces/SearchCriteriaAttributes";
import SearchNoResult from "../../components/searchNoResult/SearchNoResult";
import { selectActiveFilters, setActiveFilters as setActiveFiltersState } from "../../redux/slices/activeFiltersSlice";
import SearchProductAttributeGroup from "../../interfaces/SearchProductAttributeGroup";
import SearchProductAttribute from "../../interfaces/SearchProductAttribute";
import { CatalogProduct, ProductLibraryResponse } from "platform-services";

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchCriteria = searchParams.get("s") || "";
  const [tids, setTids] = [
    searchParams.get("tids") || "",
    (tids: string) => {
      searchParams.set("tids", tids);
      setSearchParams(searchParams);
    },
  ];
  const groupId = searchParams.get("g") || "All";
  const [filters, setFilters] = [searchParams.get("filters") || "", (x: string) => searchParams.set("filters", x)];
  const [activeFilters, setActiveFilters] = [
    useAppSelector(selectActiveFilters),
    (x: Record<string, string[]>) => dispatch(setActiveFiltersState(x)),
  ];
  const [guidedFilters, setGuidedFilters] = useState<SearchProductAttributeGroup[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchCriteriaGroupTids, setSearchCriteriaGroupTids] = useState<CatalogProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [criteriaLoading, setCriteriaLoading] = useState(true);
  const [catalogTreeLoading, setCatalogTreeLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [enableGuidedSelection, setEnableGuidedSelection] = useState(
    sessionStorage.getItem("enableGuidedSelection") !== undefined ? (sessionStorage.getItem("enableGuidedSelection") === "true" ? true : true) : true
  );
  const [sortOrderType, setSortOrderType] = useState("ClosestMatch");

  useEffect(() => {
    sessionStorage.setItem("enableGuidedSelection", enableGuidedSelection.toString());
  }, [enableGuidedSelection]);

  const [pricingAndAvailability, setPricingAndAvailability] = useState<PricingAndAvailability>({
    minPrice: null,
    maxPrice: null,
    defaultMinPrice: null,
    defaultMaxPrice: null,
    minListPriceDisplay: null,
    maxListPriceDisplay: null,
    readyToShip: false,
    isReadyToShip: false,
    maxAvailability: null,
  });
  const [searchCriteriaProducts, setSearchCriteriaProducts] = useState<SearchCriteriaProducts>({
    totalCount: 0,
    totalPages: 1,
    products: [],
  });
  const [searchCriteriaAttributes, setSearchCriteriaAttributes] = useState<SearchCriteriaAttributes>({
    productAttributes: [],
    userCountryName: "string",
  });

  const numberOfResultsPerPage = useAppSelector(selectNumberOfResultsPerPage);
  const [pageNumber, setPageNumber] = useState(1);
  const [showCategories, setShowCategories] = useState(true);
  const location = useLocation();
  const LOCALE = "US";

  useEffect(() => {
    if (isMounted) return;
    setActiveFilters(filtersFromString(filters));
  }, [filters]);

  useEffect(() => {
    if (!isMounted) return;

    if (filtersToString(activeFilters) != filters) {
      setFilters(filtersToString(activeFilters));

      searchParams.set("filters", filtersToString(activeFilters));
      setSearchParams(searchParams);
    }
  }, [activeFilters]);

  useEffect(
    () => {
      setShowCategories(groupId === "All" && tids === "");

      setProductsLoading(true);
      setCriteriaLoading(true);
      setCatalogTreeLoading(true);
      setError(false);

      setPricingAndAvailability({
        minPrice: null,
        maxPrice: null,
        defaultMinPrice: null,
        defaultMaxPrice: null,
        readyToShip: false,
        minListPrice: null,
        maxListPrice: null,
        minListPriceDisplay: null,
        maxListPriceDisplay: null,
        minListPriceUserInputDisplay: null,
        maxListPriceUserInputDisplay: null,
        maxAvailability: null,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search]
  );

  useEffect(
    () => {
      if (filters?.length > 0) return;
      setProductsLoading(true);
      setCriteriaLoading(true);
      setCatalogTreeLoading(true);
      getData().then(_ => setIsMounted(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchCriteria, groupId]
  );

  useEffect(
    () => {
      getSearchResults();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filters,
      guidedFilters,
      tids,
      numberOfResultsPerPage,
      pageNumber,
      sortOrderType,
      pricingAndAvailability.minPrice,
      pricingAndAvailability.maxPrice,
      pricingAndAvailability.readyToShip,
      pricingAndAvailability.maxAvailability,
      enableGuidedSelection,
    ]
  );

  const getSearchResults = () => {
    if (isSearching) return;
    setIsSearching(true);

    setProductsLoading(true);
    setCriteriaLoading(true);
    setCatalogTreeLoading(true);

    getFilteredData().then(onFilteredSearchComplete).catch(onFilteredSearchComplete);
  };

  useEffect(
    () => {
      if (!showCategories) {
        SearchApiService.PostSearchCriteriaAttributes(getRequest()).then(parseAttributeSearch).then(parseGuidedSelection).catch(attributeSearchError);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCategories]
  );

  const onFilteredSearchComplete = () => {
    setIsSearching(false);
    setIsMounted(true);
  };

  useEffect(() => {
    setShowCategories(groupId === "All" && tids === "");
  }, [groupId, tids]);

  const getRequest = (page?: number) => {
    const request: SearchCriteriaRequest = {
      searchCriteria: searchCriteria,
      tids: tids !== "" ? tids.split(",") : [],
      numberOfResultsPerPage: numberOfResultsPerPage,
      locale: LOCALE,
      attributes: combinedFilterAttributes(),
      catalogGroupId: groupId,
      minPrice: pricingAndAvailability.minPrice,
      maxPrice: pricingAndAvailability.maxPrice,
      maxAvailability: pricingAndAvailability.maxAvailability,
      readyToShip: pricingAndAvailability.readyToShip,
      enableGuidedSelection: enableGuidedSelection,
      orderType: sortOrderType,
    };

    if (page) {
      request.pageNumber = page;
    }

    return request;
  };

  const getData = async () => {
    const request = getRequest();

    searchApiService.PostSearchCriteriaProducts(request).then(parseProductSearch).then(parsePricingAndAvailability).catch(productSearchError);

    SearchApiService.PostSearchCriteriaProductDynamicCatalogGroupTree(request).then(parseCategorySearch).catch(categorySearchError);

    if (!showCategories) {
      SearchApiService.PostSearchCriteriaAttributes(request).then(parseAttributeSearch).then(parseGuidedSelection).catch(attributeSearchError);
    }
  };

  const getFilteredData = async () => {
    const request = getRequest(pageNumber);

    SearchApiService.PostSearchCriteriaProductDynamicCatalogGroupTree(request).then(parseCategorySearch).catch(categorySearchError);

    SearchApiService.PostSearchCriteriaFilteredProducts(request).then(parseProductSearch).then(parsePricingAndAvailability).catch(productSearchError);

    SearchApiService.PostSearchCriteriaAttributes(request).then(parseAttributeSearch).then(parseGuidedSelection).catch(attributeSearchError);
  };

  const parsePricingAndAvailability = (response: SearchCriteriaProductsResponse) => {
    setPricingAndAvailability({
      ...pricingAndAvailability,
      ...response.data,
    });
    return response;
  };

  const parseProductSearch = (response: SearchCriteriaProductsResponse) => {
    if (!!response.data.hasExactMatch) {
      const type = response.data.exactMatchCatalogNumber !== searchCriteria ? "RaiseExactMatchCatalogNumber" : response.data.type;
      navigate(`${ROUTES.PRODUCT}?catalogNumber=${response.data.exactMatchCatalogNumber}&type=${type}`, { replace: true });
    } else {
      setSearchCriteriaProducts(response.data);
      setProductsLoading(false);
    }
    return response;
  };

  const productSearchError = (error: any) => {
    setProductsLoading(false);
    setCriteriaLoading(false);
    setCatalogTreeLoading(false);
    setError(true);
  };

  const parseAttributeSearch = (response: SearchCriteriaAttributesResponse) => {
    setSearchCriteriaAttributes(response.data);
    setCriteriaLoading(false);
    return response;
  };

  const parseGuidedSelection = (response: SearchCriteriaAttributesResponse) => {
    const newGuidedFilters = response.data.productAttributes
      .filter(x => x.values.find(x => x.isGuidedSelection && x.isSelected))
      .map(x => ({ ...x, values: x.values.filter(x => x.isGuidedSelection && x.isSelected) } as SearchProductAttributeGroup));

    if (
      newGuidedFilters.length != guidedFilters.length ||
      newGuidedFilters.flatMap(x => x.values.filter(x => x.isSelected && x.isGuidedSelection)).length !=
        guidedFilters.flatMap(x => x.values.filter(x => x.isSelected && x.isGuidedSelection)).length
    ) {
      setGuidedFilters(newGuidedFilters);
    }

    return response;
  };

  const attributeSearchError = (error: any) => {
    setProductsLoading(false);
    setCriteriaLoading(false);
    setCatalogTreeLoading(false);
    setError(true);
  };

  const parseCategorySearch = (response: ProductLibraryResponse) => {
    if (response.data && response.data.length === 1) {
      setShowCategories(false);
    }

    setSearchCriteriaGroupTids(response.data);
    setCatalogTreeLoading(false);
  };

  const categorySearchError = (error: any) => {
    setCatalogTreeLoading(false);
  };

  const filtersToString = (x: Record<string, string[]>) => {
    if (Object.keys(x).length == 0) {
      return "";
    }
    return btoa(JSON.stringify(x));
  };

  const filtersFromString = (x: string) => {
    try {
      return JSON.parse(atob(x)) as Record<string, string[]>;
    } catch {
      return {};
    }
  };

  const attributesFromFilters = (filters: Record<string, string[]>) => {
    return Object.keys(filters).map(
      x =>
        ({
          name: x,
          values: filters[x].map(x => ({ name: x } as SearchProductAttribute)),
        } as SearchProductAttributeGroup)
    );
  };

  const combinedFilterAttributes = () => {
    const attributes = attributesFromFilters(filtersFromString(filters));
    guidedFilters.forEach(x => {
      const existingAttr = attributes.find(a => a.name === x.name);
      if (existingAttr) {
        existingAttr.values = [...existingAttr.values, ...x.values];
      } else {
        attributes.push(x);
      }
    });
    return attributes;
  };

  const noSearchResults = !productsLoading && !catalogTreeLoading && !searchCriteriaProducts;

  return (
    <>
      <Masthead />

      {/* breadcrumbs */}
      {!showCategories && (
        <Container sx={{ my: 0, py: 3 }}>
          <SearchBreadcrumbs
            loading={catalogTreeLoading}
            searchCriteriaGroupTids={searchCriteriaGroupTids}
            tids={tids}
            searchCriteria={searchCriteria}
          />
        </Container>
      )}

      {(error || noSearchResults || (showCategories && !noSearchResults)) && (
        <Box sx={{ py: 1, width: "60%", mx: "auto" }}>
          <Container sx={{ my: 3 }}>
            <SearchForm />
          </Container>
        </Box>
      )}

      {/* Search content */}
      <Container sx={{ my: 0, py: 3, bgcolor: "#fff", borderRadius: "4px" }}>
        {error && (
          <Alert severity="error" sx={{ my: 1 }}>
            There was an error retrieving search results. Please try again later or contact Support if the error persists.
          </Alert>
        )}
        {!error && (
          <>
            {noSearchResults && (
              <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <SearchNoResult searchCriteria={searchCriteria} />
              </Container>
            )}

            {!noSearchResults && (
              <>
                {showCategories && (
                  <SearchCategories
                    searchCriteria={searchCriteria}
                    searchCriteriaGroupTids={searchCriteriaGroupTids}
                    productsLoading={productsLoading}
                    catalogTreeLoading={catalogTreeLoading}
                  />
                )}

                {!showCategories && (
                  <SearchSelect
                    searchCriteriaProducts={searchCriteriaProducts}
                    searchCriteriaGroupTids={searchCriteriaGroupTids}
                    searchCriteriaAttributes={searchCriteriaAttributes}
                    setSearchCriteriaAttributes={setSearchCriteriaAttributes}
                    pricingAndAvailability={pricingAndAvailability}
                    setPricingAndAvailability={setPricingAndAvailability}
                    tids={tids}
                    setTids={setTids}
                    searchCriteria={searchCriteria}
                    enableGuidedSelection={enableGuidedSelection}
                    setEnableGuidedSelection={setEnableGuidedSelection}
                    numberOfResultsPerPage={numberOfResultsPerPage}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    productsLoading={productsLoading}
                    criteriaLoading={criteriaLoading}
                    catalogTreeLoading={catalogTreeLoading}
                    groupId={groupId}
                    sortOrderType={sortOrderType}
                    setSortOrderType={setSortOrderType}
                    guidedFilters={guidedFilters}
                    setGuidedFilters={setGuidedFilters}
                  />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Search;
