import { FormControl, Input, InputLabel, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface ReadOnlyInputProps {
  value: string | null;
  label: string;
  onFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const ReadOnlyInput: React.FC<ReadOnlyInputProps> = ({ value, label, onFocus, disabled = false }) => {
  return (
    <FormControl style={{ width: "100%" }}>
      <TextField
        id="pricing-min-amount"
        label={label}
        variant="outlined"
        type="text"
        value={!disabled ? value : ""}
        onFocus={onFocus}
        disabled={disabled}
      />
    </FormControl>
  );
};

interface NumberInputProps {
  value: number | null;
  label: string;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({ value, label, onBlur, disabled = false }) => {
  const useOutsideClick = (ref: any, callback: any) => {
    const handleClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  const formControlRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef) inputRef.current?.blur();
  }, [value]);

  useOutsideClick(formControlRef, () => {
    if (inputRef) inputRef.current?.blur();
  });

  const [valueRef, setValueRef] = useState(value);

  return (
    <FormControl ref={formControlRef} style={{ width: "100%" }}>
      <TextField
        id="pricing-min-amount"
        type={"number"}
        label={label}
        variant="outlined"
        ref={inputRef}
        value={!disabled ? valueRef : 0}
        onChange={e => setValueRef(Number(e.target.value))}
        onFocus={e => e.target.select()}
        onBlur={onBlur}
        disabled={disabled}
        autoFocus
      />
    </FormControl>
  );
};

interface PricingInputProps {
  value: number | null;
  displayValue: string | null;
  label: string;
  disabled?: boolean;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const PricingInput: React.FC<PricingInputProps> = ({ value, displayValue, label, onBlur, disabled = false }) => {
  const INPUT_STATES = {
    READ_ONLY: "READ_ONLY",
    NUMBER: "NUMBER",
  };
  const inputStateRef = useRef(INPUT_STATES.READ_ONLY);

  const [inputState, setInputState] = useState(inputStateRef.current);

  const onFocus = () => {
    inputStateRef.current = INPUT_STATES.NUMBER;
    setInputState(INPUT_STATES.NUMBER);
  };

  const handleOnBlur = (e: any) => {
    inputStateRef.current = INPUT_STATES.READ_ONLY;
    setInputState(INPUT_STATES.READ_ONLY);
    onBlur(e);
  };

  const renderInput = () => {
    switch (inputState) {
      case INPUT_STATES.READ_ONLY:
        return <ReadOnlyInput disabled={disabled} value={displayValue} label={label} onFocus={onFocus} />;
      case INPUT_STATES.NUMBER:
        return <NumberInput disabled={disabled} value={value} label={label} onBlur={handleOnBlur} />;
    }
  };

  return <>{renderInput()}</>;
};

export default PricingInput;
