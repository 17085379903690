import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Stepper, MobileStepper, Button, Box, Grid } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

interface CarouselProps {
  CardComponent: any;
  products: any;
}

const Carousel = (props: CarouselProps) => {
  const theme = useTheme();
  const itemsPerPage = 3;
  const [activeStep, setActiveStep] = useState(0);
  const { CardComponent } = props;
  const { products } = props;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Button onClick={handleBack} disabled={activeStep === 0}>
          <ChevronLeftIcon sx={{ width: "50px", height: "50px" }} />
        </Button>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ flexGrow: 1 }}>
          {products.slice(activeStep * 3, activeStep * 3 + itemsPerPage).map((product: any, index: any) => (
            <Box
              key={product.id}
              sx={{
                marginRight: index !== itemsPerPage - 1 ? "20px" : "0",
                display: "flex",
                justifyContent: "center",
                width: "33%",
              }}>
              <CardComponent product={product} />
            </Box>
          ))}
        </Stepper>
        <Button onClick={handleNext} disabled={activeStep === Math.ceil(products.length / 3) - 1}>
          <ChevronRightIcon sx={{ width: "50px", height: "50px" }} />
        </Button>
      </Box>
      <Grid sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}>
          <MobileStepper
            variant="dots"
            steps={Math.ceil(products.length / 3)}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400, flexGrow: 1 }}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === Math.ceil(products.length / 3)} sx={{ display: "none" }}>
                Next
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ display: "none" }}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Carousel;
