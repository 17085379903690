import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ResponseModel } from "../../interfaces/ResponseModel";

import SearchCriteriaRequest from "../../interfaces/SearchCriteriaRequest";
import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";
import { PostTemplateAttributesRequest } from "../../interfaces/PostTemplateAttributesRequest";
import { PostTemplateAttributesResponse } from "../../interfaces/PostTemplateAttributesResponse";

class SearchApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "RAISE_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
      withCredentials: true,
    };
  }

  PostSearchCriteriaProducts(data: SearchCriteriaRequest) {
    const url = `${getApiUrl(this.api)}/Search/PostSearchCriteriaProducts`;

    return axios.post(url, data, this.config);
  }

  PostSearchCriteriaAttributes(data: SearchCriteriaRequest) {
    const url = `${getApiUrl(this.api)}/Search/PostSearchCriteriaAttributes`;

    return axios.post(url, data, this.config);
  }

  PostSearchCriteriaFilteredProducts(data: SearchCriteriaRequest) {
    const url = `${getApiUrl(this.api)}/Search/PostSearchCriteriaFilteredProducts`;

    return axios.post(url, data, this.config);
  }

  PostSearchCriteriaProductTemplateIds(data: SearchCriteriaRequest) {
    const url = `${getApiUrl(this.api)}/Search/PostSearchCriteriaProductTemplateIds`;

    return axios.post(url, data, this.config);
  }

  PostSearchCriteriaProductDynamicCatalogGroupTree(data: SearchCriteriaRequest) {
    const url = `${getApiUrl(this.api)}/Search/PostSearchCriteriaProductDyamicCatalogGroupTree`;

    return axios.post(url, data, this.config);
  }

  HasValidSearchCriteria(data: string): Promise<AxiosResponse<ResponseModel>> {
    const url = `${getApiUrl(this.api)}/Search/HasValidSearchCriteria?searchCriteria=${data}`;

    return axios.post<null, AxiosResponse<ResponseModel>>(url, null, this.config);
  }

  PostTemplateAttributes(request: PostTemplateAttributesRequest): Promise<AxiosResponse<PostTemplateAttributesResponse>> {
    const url = `${getApiUrl(this.api)}/Search/PostTemplateAttributes`;

    return axios.post<PostTemplateAttributesResponse>(url, request, this.config);
  }
}

const searchApiService = new SearchApiService();
export default searchApiService;
