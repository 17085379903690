import { Box, ButtonBase, Typography } from "@mui/material";
import { IAttribute } from "../interfaces/IAttribute";
import { TeaserCard } from "@rockwell-automation-inc/ra-meatball";

interface AttriubteCardReadOnlyProps {
  selectedAttribute: IAttribute;
}

const AttributeCardReadOnly = (props: AttriubteCardReadOnlyProps) => {
  const { selectedAttribute } = props;

  return (
    <ButtonBase sx={{ width: "50%", gap: "5px", padding: "5px", minHeight: "46px" }}>
      <TeaserCard
        raVariant="vertical"
        hover={false}
        sx={{
          flexShrink: "0",
          flexWrap: "wrap",
          fontFamily: "inherit",
          borderRadius: "4px",
          width: "100%",
          border: "solid 2px #003E7E",
          ":hover": {
            boxShadow: "2px 2px 10px 2px #00000040",
          },
          display: "flex",
          flexDirection: "row",
          padding: "5px",
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            minHeight: "36px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "5px",
          }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flex: "1 0 0 ", width: "100%" }}>
            <Typography
              sx={{
                height: "auto",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Barlow !important",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "18px",
                textAlign: "left",
                width: "100%",
              }}>
              {selectedAttribute.value} {selectedAttribute.units}
            </Typography>
          </Box>
        </Box>
      </TeaserCard>
    </ButtonBase>
  );
};

export default AttributeCardReadOnly;
