import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Tooltip, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProductComponent from "../../interfaces/ProductComponent";
import Response from "../../services/apis/Response";
import { useSelector } from "react-redux";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import { SelectableAccessory } from "../../interfaces/Accessory";
import MUICloseIcon from "@mui/icons-material/Close";
import { LoadingIndicator } from "@rockwell-automation-inc/ra-meatball";
import toast from "../../util/toast";
import { useAppDispatch } from "../../redux/hooks";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import { BOMApiService, Id, selectUser, usePSAppSelector } from "platform-services";
import { BOM } from "../../interfaces/BOM";
import { PostBOMItem } from "../../interfaces/PostBOMItem";
import { selectSelBOM, setSelBOM } from "../../redux/slices/selectedBOMSlice";
import { PostBOM } from "../../interfaces/PostBOM";
import { getConfigBOMItemThumbPrint } from "../../util/ConfigBOMItemThumbprint";
import { featureFlagItpacs769 } from "../productResultItem/disassembledProduct/featureFlag";
import { TroubleshootOutlined } from "@mui/icons-material";

interface ProductBomButtonProps {
  componentArray: ProductComponent[];
}

const ProductBomButtonMultiple = (props: ProductBomButtonProps) => {
  const dispatch = useAppDispatch();
  const selProject = useSelector(selectSelProject);
  const selBOM = useSelector(selectSelBOM);
  const { componentArray } = props;
  const user = usePSAppSelector(selectUser);
  const PAGES = {
    BOM_SELECT: "BOM_SELECT",
    BOM_ACTIONS: "BOM_ACTIONS",
  };
  const LOCALE = "US";
  const [qty, setQty] = useState(1);
  const [currentPage, setCurrentPage] = useState<(typeof PAGES)[keyof typeof PAGES]>(PAGES.BOM_SELECT);
  const [titleMessage, setTitleMessage] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAccessories, setSelectedAccessories] = useState<SelectableAccessory[]>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [componentCount, setComponentCount] = useState<number>(0);

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmModalOpen = () => {
    setConfirmModalOpen(true);
  };

  const addComponenetsToSelectedBOM = async (bomId: string, savingToast: Id) => {
    const promises = [];
    for (const component of componentArray) {
      const product: PostBOMItem = {
        name: component.catalogNumber,
        templateTitle: component.templateTitle,
        description: component.description,
        quantity: (featureFlagItpacs769 && component.componentQty) || 1,
        productType: component.productType,
        configThumbprint: component.configThumbprint,
        listPrice: component.listPrice,
        photo: component.photo,
        userNotes: component.userNotes,
        bomItemParentId: component.bomItemParentId,
        sourceType: component.sourceType,
        children: [],
      };
      const result = await BOMApiService.postBOMItem(bomId, product.sourceType, product);
      const response = new Response(result);
      promises.push(response);
    }

    Promise.all(promises)
      .then(results => {
        toast.loadedSuccess(savingToast, `All Disassembled products added to "${selProject?.name}"`);
        setCurrentPage(PAGES.BOM_ACTIONS);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
        toast.dismiss(savingToast);
        toast.error(`Unable to add Disassembled products to "${selProject?.name}"`);
      });
  };

  const createNewBOM = async () => {
    let bom: BOM;
    if (selProject?.guid !== undefined) {
      const postBom: PostBOM = {
        name: "DEFAULT BOM",
        projectId: selProject?.guid,
      };
      try {
        const result = await BOMApiService.postBOM(postBom);
        const response = new Response(result);
        if (response.isSuccessful()) {
          dispatch(setSelBOM(response.getData()));
          bom = response.getData();
          return bom;
        }
      } catch (e) {}
    }
  };

  const addProductToSelectedBOM = async (newBOM?: BOM | undefined) => {
    setIsLoading(true);
    const savingToast = toast.loading("Saving...");

    try {
      if (newBOM) {
        await addComponenetsToSelectedBOM(newBOM.id, savingToast);
      }
      if (newBOM === undefined && selBOM) {
        await addComponenetsToSelectedBOM(selBOM.id, savingToast);
      }
    } catch (e) {
      setIsLoading(false);
      toast.dismiss(savingToast);
      toast.error(
        <>
          <div>Unable to add Disassembled products to {selProject?.name}</div>
          <div>
            The selected Project has reached the maximum number of items that can be added. Please contact product support for help, if needed.
          </div>
        </>
      );
      console.log(e);
    }

    setIsLoading(false);
    const tabClick = new CustomEvent("trackAddToBom", {
      detail: {
        action: "Add User Assembled Products to Bom",
        properties: {
          category: "WebApp",
          label: "Add User Assembled Prodcuts to Bom",
          products: componentArray,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleMultiAddToBom = async () => {
    if (componentArray.length < 1) return;
    if (selBOM === null) {
      createNewBOM().then((newBOM: BOM | undefined) => {
        addProductToSelectedBOM(newBOM);
      });
    } else {
      addProductToSelectedBOM();
    }
    //handleConfirmModalOpen();
  };

  useEffect(() => {
    if (!user || !selProject) {
      setTitleMessage(!user ? "Login to add all products to project" : "Create a new Project before adding to project");
      setDisabled(true);
    } else {
      setTitleMessage("Add all products to project");
      setDisabled(false);
    }
  }, [user, selBOM]);

  useEffect(() => {
    let totalCount = 0;
    componentArray.forEach(component => {
      if (featureFlagItpacs769 && component.componentQty !== undefined) {
        totalCount += component.componentQty;
      } else {
        totalCount += 1;
      }
    });
    setComponentCount(totalCount);
  }, [componentArray]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #E5E6E9",
          marginTop: "20px",
        }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tooltip placement="top" arrow title={titleMessage}>
            <Box>
              <Button
                disabled={disabled}
                sx={{
                  border: "none",
                }}
                onClick={() => {
                  handleMultiAddToBom();
                }}>
                <AddIcon />

                <Typography
                  sx={{
                    textAlign: "center",
                    marginLeft: "5px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontFamily: "Barlow !important",
                  }}>
                  ADD {componentCount}
                  {` `}
                  {featureFlagItpacs769 ? "COMPONENTS" : "PIECES"}
                  {` `}
                  TO PROJECT : {selProject?.name || selBOM?.name}
                </Typography>
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      {confirmModalOpen && (
        <Modal open={true}>
          {isLoading ? (
            <>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  minWidth: 500,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}>
                <LoadingIndicator></LoadingIndicator>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  minWidth: 500,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}>
                  <Typography variant="h6" component="h2" sx={{ paddingRight: "5px" }}>
                    Success
                  </Typography>
                  <MUICloseIcon sx={{ color: "#2a76c4" }} onClick={handleConfirmModalClose}></MUICloseIcon>
                </Box>
                <Box>
                  <Typography>
                    added {componentCount} items to : " {selProject?.name} "
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default ProductBomButtonMultiple;
