import { createContext, useContext, useState } from "react";

interface ConfiguratorDataContextProps {
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  isViewSummaryVisible: boolean;
  setIsViewSummaryVisible: (isVisible: boolean) => void;
}

const ConfiguratorDataContext = createContext<ConfiguratorDataContextProps | null>({} as ConfiguratorDataContextProps);

export default function ConfiguratorDataContextProvider({ children }: React.PropsWithChildren) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isViewSummaryVisible, setIsViewSummaryVisible] = useState(false);

  return (
    <ConfiguratorDataContext.Provider value={{ searchQuery, setSearchQuery, isViewSummaryVisible, setIsViewSummaryVisible }}>
      {children}
    </ConfiguratorDataContext.Provider>
  );
}

export function useConfiguratorData() {
  const context = useContext(ConfiguratorDataContext);
  return context!;
}
