import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { GetURLQueryVariable } from "./GetURLQueryVariable";
import { SetURLQueryVariables } from "./SetURLQueryVariables";
import { PROJECT_GUID, PROJECT_ID } from "../services/util/SessionStorage";
interface GlobalRouteListenerProps {
  children: React.ReactNode;
}

const GlobalRouteListener: React.FC<GlobalRouteListenerProps> = ({ children }) => {
  const location = useLocation();

  const handleQueryEvent = () => {
    const projectGuid = GetURLQueryVariable(PROJECT_GUID);
    const projectId = GetURLQueryVariable(PROJECT_ID);
    if (projectGuid) {
      sessionStorage.setItem(PROJECT_GUID, projectGuid);
    }
    if (projectId) sessionStorage.setItem(PROJECT_ID, projectId);

    SetURLQueryVariables();
  };

  useEffect(() => {
    handleQueryEvent();
  }, [location]);

  return <>{children}</>;
};

export default GlobalRouteListener;
