import { useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  TextField,
  FormControl,
  InputAdornment,
  Box,
  DialogTitle,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./AddUserDefinedProduct.scss";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import SearchApiService from "../../services/apis/SearchApiService";
import SearchCriteriaRequest from "../../interfaces/SearchCriteriaRequest";
import { Project } from "../../interfaces/Project";
import { useSelector } from "react-redux";
import { selectSelBOM, setSelBOM } from "../../redux/slices/selectedBOMSlice";
import { BOM } from "../../interfaces/BOM";
import { BOMApiService } from "platform-services";
import { BOMItemApiService } from "platform-services";
import Response from "../../services/apis/Response";
import { PostBOM } from "../../interfaces/PostBOM";
import { useAppDispatch } from "../../redux/hooks";
import toast from "../../util/toast";
import { PostBOMItem } from "../../interfaces/PostBOMItem";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";

interface Props {
  searchCriteria: string;
}

const AddUserDefinedProduct = (props: Props) => {
  const { searchCriteria } = props;
  const LOCALE = "US";
  const [open, setOpen] = useState(false);

  const [catalogNumber, setCatalogNumber] = useState("");
  const [listPrice, setListPrice] = useState("");
  const [description, setDescription] = useState("");

  const [catalogNumberValidating, setCatalogNumberValidating] = useState(false);
  const [catalogNumberValid, setCatalogNumberValid] = useState(false);
  const selBOM = useSelector(selectSelBOM);
  const selProject = useSelector(selectSelProject);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line no-unused-vars
  const [billOfMaterials, setBillOfMaterials] = useState<Project | null>(null);
  const [qty, setQty] = useState(1);

  const PAGES = {
    BOM_SELECT: "BOM_SELECT",
    BOM_ACTIONS: "BOM_ACTIONS",
  };

  const [currentPage, setCurrentPage] = useState<(typeof PAGES)[keyof typeof PAGES]>(PAGES.BOM_SELECT);

  const [product, setProduct] = useState<DetailedProduct>();

  useEffect(() => {
    if (searchCriteria !== "") {
      const keywords = searchCriteria.split(" ");
      if (keywords.length === 1) {
        setCatalogNumber(searchCriteria);
      }
    }
  }, [searchCriteria]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleClearProduct();
    setOpen(false);
  };

  const handleClearProduct = () => {
    setProduct(undefined);
    setCurrentPage(PAGES.BOM_SELECT);
    setBillOfMaterials(null);
  };

  const handleCatalogNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCatalogNumber(event.target.value);
  };

  const handleCatalogNumberValidate = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    if (event.target.value.length > 0) {
      setCatalogNumberValidating(true);

      const request: SearchCriteriaRequest = {
        searchCriteria: event.target.value,
        tids: [],
        numberOfResultsPerPage: 25,
        locale: LOCALE,
        attributes: [],
      };
      SearchApiService.PostSearchCriteriaProducts(request)
        .then(response => {
          setCatalogNumberValidating(false);
          setCatalogNumberValid(!response.data.hasExactMatch);
        })
        .catch(error => console.log(error));
    }
  };

  const handleListPriceChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setListPrice(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const canSubmit = () => {
    if (!catalogNumberValid) {
      return false;
    }
    if (catalogNumber === "") {
      return false;
    }
    if (listPrice === "") {
      return false;
    } else {
      const regex = /^\d{1,5}$|(?=^.{1,5}$)^\d+\.\d{0,2}$/gm;
      if (!regex.test(listPrice)) {
        return false;
      }
    }

    return true;
  };

  const createNewBOM = async (savingToast: any) => {
    let bom: BOM;
    if (selProject?.guid !== undefined) {
      const postBom: PostBOM = {
        name: "DEFAULT BOM",
        projectId: selProject?.guid,
      };
      try {
        const result = await BOMApiService.postBOM(postBom);
        const response = new Response(result);
        if (response.isSuccessful()) {
          dispatch(setSelBOM(response.getData()));
          bom = response.getData();
          return bom;
        }
      } catch (e) {
        toast.dismiss(savingToast);
        toast.error(`Unable to add ${product?.catalogNumber} to bom "${postBom.name}"`);
        console.log(e);
      }
    }
  };

  const addProductToSelectedBOM = async (userDefinedproduct: PostBOMItem, savingToast: any, newBOM?: BOM | undefined) => {
    try {
      if (newBOM) {
        const result = await BOMItemApiService.postBOMItem(newBOM.id, userDefinedproduct);

        const response = new Response(result);

        if (response.isSuccessful()) {
          setOpen(false);
          toast.loadedSuccess(savingToast, `${userDefinedproduct.name} added to: "${selProject?.name}"`);
        }
      }
      if (newBOM === undefined && selBOM) {
        const result = await BOMItemApiService.postBOMItem(selBOM.id, userDefinedproduct);

        const response = new Response(result);

        if (response.isSuccessful()) {
          setOpen(false);
          toast.loadedSuccess(savingToast, `${userDefinedproduct.name} added to: "${selProject?.name}"`);
        }
      }
    } catch (e) {
      toast.dismiss(savingToast);
      toast.error(`Unable to add ${userDefinedproduct.name} to "${selProject?.name}"`);
      console.log(e);
    }
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const savingToast = toast.loading("Saving...");
    const userDefinedproductBOMItem: PostBOMItem = {
      name: catalogNumber,
      description: description,
      listPrice: Number(listPrice),
      quantity: qty,
      productType: "Product",
      sourceType: "ProposalWorksExactMatchUserDefined",
      children: [],
    };

    if (selBOM === null) {
      createNewBOM(savingToast).then((newBOM: BOM | undefined) => {
        addProductToSelectedBOM(userDefinedproductBOMItem, savingToast, newBOM);
      });
    } else {
      addProductToSelectedBOM(userDefinedproductBOMItem, savingToast);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Add User-Defined Product
      </Button>
      <Dialog open={open} onClose={() => setOpen(true)} fullWidth={true} maxWidth="lg">
        {!product && (
          <>
            <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" sx={{ px: 4, pt: 4 }}>
              <Typography variant="h5" fontWeight="bold">
                Add User Defined Product
              </Typography>
              <ButtonBase onClick={handleClose}>
                <CloseIcon />
              </ButtonBase>
            </DialogTitle>
            <DialogContent sx={{ px: 10, pb: 10 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl sx={{ m: 1, width: "60%" }} variant="standard">
                  <TextField
                    id="catalogNumber"
                    value={catalogNumber}
                    onChange={handleCatalogNumberChange}
                    onBlur={handleCatalogNumberValidate}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <>
                          {catalogNumber.length > 0 && (
                            <InputAdornment position="end">{catalogNumberValidating && <CircularProgress size={20} />}</InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    label={
                      catalogNumberValid ? (
                        <Typography sx={{ fontSize: "16px" }}>Catalog Number</Typography>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <CancelOutlinedIcon sx={{ color: "#cd163f" }} />
                          <Typography sx={{ fontSize: "16px", color: "#cd163f" }}>Catalog Number</Typography>
                        </Box>
                      )
                    }
                    autoFocus
                  />
                  {!catalogNumberValid && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        paddingLeft: "15px",
                        paddingTop: "5px",
                        color: "#cd163f",
                      }}>
                      Rockwell product can not be added.
                    </Typography>
                  )}
                </FormControl>

                <FormControl sx={{ m: 1, width: "30%" }} variant="standard">
                  <TextField
                    id="listPrice"
                    type="number"
                    value={listPrice}
                    onChange={handleListPriceChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="List Price"
                  />
                </FormControl>
              </Box>

              <Box sx={{ my: 3, px: 1 }}>
                <TextField id="description" label="Description" multiline rows={3} fullWidth value={description} onChange={handleDescriptionChange} />
              </Box>

              <Grid container direction="row" justifyContent="flex-end" gap={1} sx={{ pt: 4 }}>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button variant="contained" disabled={!canSubmit()} onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default AddUserDefinedProduct;
