import { useState, useEffect, FormEvent, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, SearchIcon } from "@rockwell-automation-inc/ra-meatball";
import ROUTES from "../../constants/Routes";
import { Alert, Box, Typography } from "@mui/material";
import { findNestedProduct } from "../../helpers/Utils";
import searchApiService from "../../services/apis/SearchApiService";
import { ResponseModel } from "../../interfaces/ResponseModel";
import { AxiosResponse } from "axios";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { CatalogProduct, selectCatalog, usePSAppSelector } from "platform-services";

const SearchForm = () => {
  const [query, setQuery] = useState("");
  const [groupId, setGroupId] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);
  const selectRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const catalog = usePSAppSelector(selectCatalog);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  useEffect(() => {
    setQuery(new URLSearchParams(location.search).get("s") || "");
    if (!location.search) {
      const urlGroupId = location.pathname.split("/")[2];
      if (catalog?.find((i: CatalogProduct) => i.catalogGroupId == urlGroupId)) {
        setGroupId(urlGroupId);
      }
    } else {
      setGroupId(new URLSearchParams(location.search).get("g") || "All");
    }
  }, [location.search, location.pathname, catalog]);

  useEffect(() => {
    setAnchorEl(selectRef.current);
  }, [selectRef]);

  useEffect(() => {
    handleBrowse();
    //eslint-disable-next-line
  }, [groupId]);

  const handleBrowse = () => {
    const path = location.pathname;

    if (path.split("/")[1] === "browse" && groupId !== "All") {
      navigate(ROUTES.BROWSE + "/" + groupId);
    }
  };

  const handleSearchSubmit = (event: FormEvent) => {
    event.preventDefault();
    searchApiService
      .HasValidSearchCriteria(query)
      .then((response: AxiosResponse<ResponseModel>) => {
        setIsValid(response.data.success);
        setErrorMessage(response.data.message);
        if (response.data.success) {
          navigate(`${ROUTES.SEARCH}?s=${query}`);
          const searchCatalog = new CustomEvent("searchSubmit", {
            detail: {
              action: "SearchForm",
              properties: {
                category: "WebApp",
                label: query,
              },
            },
          });
          document.getElementById("root")?.dispatchEvent(searchCatalog);
        } else {
          setDisplayErrorMessage(true);
          setTimeout(() => {
            setDisplayErrorMessage(false);
          }, 5000);
        }
      })
      .catch(() => {
        return false;
      });
  };

  const loadCurrentProduct = (): CatalogProduct | undefined => {
    const product: CatalogProduct | undefined = findNestedProduct(catalog, groupId, () => {});

    return product;
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  function flatten(data: CatalogProduct[]) {
    const result: CatalogProduct[] = [];
    recursive(data);
    return result;

    function recursive(data: CatalogProduct[]) {
      data.forEach(member => {
        result.push(member);
        recursive(member.childGroups);
      });
    }
  }

  const getCatalogs = () => {
    const currentProduct = loadCurrentProduct();

    if (currentProduct && currentProduct.childGroups.length > 0) {
      let arr = [currentProduct, ...currentProduct.childGroups];

      return arr;
    } else if (currentProduct && currentProduct.templates.length > 0) {
      return [currentProduct];
    }
    return catalog;
  };

  const handleCloseClick = () => {
    setQuery("");
  };
  return (
    <>
      <Box display="flex" flexDirection="row" position="relative" sx={{ width: "100%" }}>
        <Paper
          variant="outlined"
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            border: "1px solid #D9D9D9",
            position: "relative",
          }}
          onSubmit={handleSearchSubmit}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by catalog # or keyword"
            inputProps={{
              "aria-label": "Search by catalog number or keyword",
            }}
            value={query}
            onChange={handleQueryChange}
          />
          <IconButton aria-label="Clear" sx={{ borderRadius: "50%", padding: "7px", color: "#6D6E71" }} onClick={handleCloseClick}>
            <CloseIcon />
          </IconButton>
          <IconButton
            type="submit"
            aria-label="Search"
            disableRipple
            sx={{ padding: "7px 22px 7px 22px", borderRadius: "4px", color: "#FFFFFF", backgroundColor: "#2A79C4" }}>
            <SearchIcon />
          </IconButton>
          {!isValid && displayErrorMessage && errorMessage && (
            <Alert
              severity="warning"
              sx={{
                position: "absolute",
                bottom: "-200%",
                left: "0%",
                width: "auto",
                backgroundColor: "white !important",
                color: "black !important",
                border: "1px solid grey",
                borderRadius: "4px",
                ":after, :before": {
                  content: "''",
                  display: "block",
                  position: "absolute",
                  bottom: "100%",
                  width: 0,
                  height: 0,
                },
                ":before": {
                  left: "19px",
                  border: "11px solid transparent",
                  "border-bottom-color": "grey",
                },
                ":after": {
                  left: "20px",
                  border: "10px solid transparent",
                  borderBottomColor: "#fff",
                },
              }}>
              <AnnouncementIcon fontSize="large" sx={{ fill: "#FFA300", height: "24px !important", width: "24px !important" }} />
              <Typography variant="subtitle1">{errorMessage}</Typography>
            </Alert>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default SearchForm;
