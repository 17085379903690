import { useEffect } from "react";
import { Button, CardContent } from "@mui/material";
import projectApiService from "../../services/apis/ProjectApiService";
import { PostLog } from "../../interfaces/PostLog";
import { LogType } from "../../interfaces/LogType";
import { LogSourceType } from "../../interfaces/LogSourceType";
import { useAuthContext } from "platform-services";

interface CadenasContentProps {
  catalogNumber: string | undefined;
  cadenasDisplayUrl: string;
  loggedIn: boolean;
}

const CadenasContent = (props: CadenasContentProps) => {
  const { catalogNumber, cadenasDisplayUrl, loggedIn } = props;
  const authContext = useAuthContext();

  useEffect(() => {
    loggedIn && logEvent();
  }, []);

  const logEvent = () => {
    const log: PostLog = {
      logType: LogType.Cadenas,
      sourceType: LogSourceType.AdvisorPDP,
      content: `${catalogNumber}; Drawing Tab Viewed Cadenas Available`,
    };
    projectApiService.postLog(log);
  };

  return !loggedIn ? (
    <Button
      className="request_cadenas_button"
      onClick={() => {
        authContext.signinRedirect();
      }}>
      Log In To View and Download Drawings
    </Button>
  ) : (
    <CardContent sx={{ display: "flex", justifyContent: "center" }}>
      <iframe
        className="cadenas_display"
        title={"cadenas-" + catalogNumber}
        src={cadenasDisplayUrl}
        frameBorder="0"
        scrolling="no"
        allow="fullscreen"></iframe>
    </CardContent>
  );
};

export default CadenasContent;
