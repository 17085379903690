import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { CloseIcon } from "@rockwell-automation-inc/ra-meatball";
import { useEffect, useState } from "react";
import backgroundImage from "../aside/Background-texture_Red-blue.png";
import "../aside/Aside.scss";

const OldAside = () => {
  const [isOpen, setIsOpen] = useState(!sessionStorage.getItem("aside") || sessionStorage.getItem("aside") === "open");
  const [isMounted, setIsMounted] = useState(false);
  const [className, setClassName] = useState("");

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    getClassName();
  });

  const getClassName = () => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      if (isOpen) {
        setClassName("slide-in");
      } else {
        setClassName("slide-out");
      }
    }
  };

  useEffect(() => {
    switch (isOpen) {
      case true:
        sessionStorage.setItem("aside", "open");
        break;
      case false:
        sessionStorage.setItem("aside", "closed");
        break;
    }
  }, [isOpen]);

  const oldSplashPage = () => {
    if (!window.location.origin.includes("dev")) {
      <Box
        component="aside"
        style={{
          transition: "all 1s ease 0s",
          position: "fixed",
          zIndex: 91,
          width: "26rem",
          boxShadow: "0 1px 5px 0 rgb(0 0 0 / 25%)",
          background: "linear-gradient(90deg,rgba(0,0,0,.63) 0,transparent) 50%/cover no-repeat",
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0) 100%), url("${backgroundImage}")`,
        }}
        sx={{
          pt: 2,
          pb: 1.5,
          px: 2,
        }}
        className={`aside ${className}`}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography
            variant="h4"
            component="p"
            fontWeight="bold"
            color="#fff"
            textTransform="uppercase"
            lineHeight="1"
            sx={{
              width: "90%",
              fontSize: "3rem",
              fontFamily: "BarlowBold,Arial,Helvetica Neue,Helvetica,sans-serif!important",
            }}>
            WHAT'S NEW
          </Typography>
          <ButtonBase sx={{ pt: 0, mt: 1, mr: 0.5, height: "fit-content" }} onClick={onClose}>
            <CloseIcon sx={{ filter: "invert(100%)" }} fontSize="small" />
          </ButtonBase>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" component="li" color="#fff" lineHeight="1.5rem" fontSize="1rem" sx={{ mb: 1, ml: 1 }}>
            Easily locate our Best Selling products when searching for a Rockwell Automation solution.
          </Typography>
          <Typography variant="subtitle2" component="li" color="#fff" lineHeight="1.5rem" fontSize="1rem" sx={{ mb: 1, ml: 1 }}>
            Use our new family level search to locate one of our
            <b>PowerFlex Drive</b>
            products.
          </Typography>
          <Typography variant="subtitle2" component="li" color="#fff" lineHeight="1.5rem" fontSize="1rem" sx={{ mb: 1, ml: 1 }}>
            Connect with your local distributor when ready to submit your project for review.
          </Typography>
          <Typography variant="subtitle2" component="li" color="#fff" lineHeight="1.5rem" fontSize="1rem" sx={{ mb: 1, ml: 1 }}>
            New Control System Designer Family Addition -<b>FlexHA 5000</b>
            High availability I/O is now available for configuration.
          </Typography>
          <Typography variant="subtitle2" component="li" color="#fff" lineHeight="1.5rem" fontSize="1rem" sx={{ mb: 1, ml: 1 }}>
            Use our simplified navigation to view the bill of material (BOM) associated with a Control System configuration
          </Typography>
        </Box>
        <Box>
          {/*<Button variant="primary" sx={{ width: "100%", fontSize: "1rem" }}>*/}
          {/*  Site Feedback*/}
          {/*</Button>*/}
        </Box>
      </Box>;
    }
  };

  return <>{oldSplashPage()}</>;
};

export default OldAside;
