import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect } from "react";
import { IAttribute } from "../interfaces/IAttribute";
import AttributeCardReadOnly from "./AttributeCardReadOnly";
import FamilySearch from "../../../../pages/familySearch/FamilySearch";
import DOMPurify from "dompurify";

interface AttributeTextInputProps {
  selectedAttribute: IAttribute;
  updateConfiguratorWithUserEnteredValue: (attribute: IAttribute, value: string) => void;
}

const AttributeTextInput = (props: AttributeTextInputProps) => {
  const { selectedAttribute, updateConfiguratorWithUserEnteredValue } = props;

  const [valueString, setValueString] = useState<string>("");
  let tempValueString: string = "";

  const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    tempValueString += tempValueString + event.target.value;
    setValueString(tempValueString);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleUserEnteredValue();
    }
  };

  useEffect(() => {
    setValueString("");
  }, [selectedAttribute]);

  const handleUserEnteredValue = () => {
    updateConfiguratorWithUserEnteredValue(selectedAttribute, valueString);
  };

  return (
    <>
      <Box sx={{ height: "464px", padding: "10px 5px", gap: "10px", flex: "1 0 0" }}>
        {selectedAttribute.readOnly === false && (
          <Box
            sx={{
              display: "flex",
              width: "290px",
              minHeight: "46px",
              padding: "5px",
              alignItems: "flex-start",
              alignContent: "flex-start",
              gap: "5px",
              flexWrap: "wrap",
              borderRadius: "var(--spacing-spacing-0, 4px)",
              border: "1px solid var(--Data-visualization-Grey-300, #A6A9AB)",
              background: "var(--rafl-core-color-silver-100, #FFF)",
            }}>
            <Box
              sx={{
                display: "flex",
                width: "280px",
                minHieght: "36px",
                alignItems: "flex-start",
                alignContent: "flex-start",
                gap: "5px",
                flexShrink: "0",
                flexWrap: "wrap",
              }}>
              <Typography sx={{ fontFamily: "Barlow !important", fontSize: "16px", fontStyle: "normal", fontWieght: "400", lineHeight: "22px" }}>
                Enter a value for {selectedAttribute.displayText}
              </Typography>
            </Box>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              display="flex"
              flexDirection="row"
              position="relative"
              sx={{ width: "100%", paddingTop: "20px" }}>
              <TextField
                sx={{ width: "100%" }}
                label={selectedAttribute.value ? selectedAttribute.value : "Enter a value*"}
                value={valueString}
                onChange={handleValueChange}
                onKeyDown={e => handleKeyDown(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Value"
                        disabled={valueString.length < 1 ? true : false}
                        disableRipple
                        sx={{
                          padding: "7px 22px 7px 22px",
                          borderRadius: "4px",
                          color: "#FFFFFF",
                          backgroundColor: "#2A79C4",
                          width: "62px",
                          height: "32px",
                        }}
                        onClick={() => handleUserEnteredValue()}>
                        <CheckIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
        {selectedAttribute.class === "String" && selectedAttribute.value && selectedAttribute.readOnly === true && (
          <Box>
            <Typography sx={{ fontFamily: "Barlow !important", fontSize: "16px", fontStyle: "normal", fontWieght: "400", lineHeight: "22px" }}>
              Note:
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedAttribute.value) }}
              sx={{
                whiteSpace: "pre-line",
                fontFamily: "Barlow !important",
                fontSize: "16px",
                fontStyle: "normal",
                fontWieght: "400",
                lineHeight: "22px",
              }}></Typography>
          </Box>
        )}
        {(selectedAttribute.class === "Real" || selectedAttribute.class === "Integer") && (
          <>
            {selectedAttribute.readOnly ? (
              <AttributeCardReadOnly selectedAttribute={selectedAttribute} />
            ) : (
              <Box sx={{ paddingTop: "20px" }}>
                <Typography sx={{ fontFamily: "Barlow !important", fontSize: "16px", fontStyle: "normal", fontWieght: "400", lineHeight: "22px" }}>
                  Units: {selectedAttribute.units}
                </Typography>
                <Typography sx={{ fontFamily: "Barlow !important", fontSize: "16px", fontStyle: "normal", fontWieght: "400", lineHeight: "22px" }}>
                  Minimum Value: {selectedAttribute.min}
                </Typography>
                <Typography sx={{ fontFamily: "Barlow !important", fontSize: "16px", fontStyle: "normal", fontWieght: "400", lineHeight: "22px" }}>
                  Maximum Value: {selectedAttribute.max}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AttributeTextInput;
