import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSelProject } from "../redux/slices/selectedProjectSlice";
import { setSelBOM } from "../redux/slices/selectedBOMSlice";
import { BOM } from "../interfaces/BOM";
import { Project } from "../interfaces/Project";

const ProjectChangeDetection = () => {
  const dispatch = useDispatch();

  const handleProjectChangeEvent = (project: Project) => {
    dispatch(setSelProject(project || null));
  };

  const handleBOMChangeEvent = (bom: BOM) => {
    dispatch(setSelBOM(bom || null));
  };

  useEffect(() => {
    window.addEventListener(
      "projectChange",
      function (evt: Event) {
        handleProjectChangeEvent((evt as CustomEvent).detail);
      },
      false
    );
    window.addEventListener(
      "bomChange",
      function (evt: Event) {
        handleBOMChangeEvent((evt as CustomEvent).detail);
      },
      false
    );
  }, []);

  return <></>;
};

export default ProjectChangeDetection;
