import { Typography } from "@mui/material";
import AddUserDefinedProduct from "../addUserDefinedProduct/AddUserDefinedProduct";

interface SearchNoResultProps {
  searchCriteria: string;
}

const SearchNoResult = (props: SearchNoResultProps) => {
  const { searchCriteria } = props;

  return (
    <>
      <Typography sx={{ mb: 1 }}>Your search returned no results.</Typography>

      <AddUserDefinedProduct searchCriteria={searchCriteria} />
    </>
  );
};

export default SearchNoResult;
