import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const initialState: Record<string, string[]> = {};

export const activeFilters = createSlice({
  name: "activeFilters",
  initialState,
  reducers: {
    setActiveFilters: (state, action: PayloadAction<Record<string, string[]>>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setActiveFilters } = activeFilters.actions;
export const selectActiveFilters = (state: RootState) => state.activeFilters;
export default activeFilters.reducer;
