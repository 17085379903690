import { Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PARAMS from "../../constants/PARAMS";

interface ConfiguratorButtonProps {
  templateID: string;
  productID?: string;
  width?: string;
}

const ConfiguratorButton: React.FC<ConfiguratorButtonProps> = ({ templateID, productID, width }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(PARAMS.CONFIGURATOR, "true");
    if (productID) {
      searchParams.set(PARAMS.PID, productID);
      searchParams.set(PARAMS.TIDS, templateID);
    } else {
      searchParams.set(PARAMS.TIDS, templateID);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleConfigureClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleNavigation();
  };

  return (
    <>
      <Button sx={{ width: width }} onClick={event => handleConfigureClick(event)} variant="outlined">
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Barlow !important",
            fontWeight: "500",
            fontSize: "16px",
          }}>
          CONFIGURE
        </Typography>
      </Button>
    </>
  );
};

export default ConfiguratorButton;
