import { BrowserRouter, Routes, Route } from "react-router-dom";
import ROUTES from "../../constants/Routes";
import Box from "@mui/material/Box";
import Home from "../../pages/home/Home";
import Search from "../../pages/search/Search";
import Product from "../../pages/product/Product";
import Browse from "../../pages/browse/Browse";
import { ToastContainer } from "react-toastify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import toast from "../../util/toast";
import GlobalRouteListener from "../../util/GlobalRouteListener";
import SystemDesigners from "../../pages/systemDesigners/SystemDesigners";
import ProjectChangeDetection from "../../util/ProjectChangeDetection";
import ConfiguratorHome from "../../components/rockwellConfigurator/pages/home/Home";
import FamilySearch from "../../pages/familySearch/FamilySearch";
import { RockwellFooter, RockwellHeader, selectCatalog, usePSAppSelector } from "platform-services";
import OldAside from "../oldAside/oldAside";
import DrawerLaunch from "../rockwellConfigurator/components/drawerLaunch/DrawerLaunch";

const LayoutView = () => {
  const catalog = usePSAppSelector(selectCatalog);
  return (
    <>
      <BrowserRouter>
        <RockwellHeader />
        <Box component="main">
          <Box>
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={
                  <GlobalRouteListener>
                    <Home />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.BROWSE}
                element={
                  <GlobalRouteListener>
                    <Browse />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={`${ROUTES.BROWSE}/:catalogGroupId`}
                element={
                  <GlobalRouteListener>
                    <Browse />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.SEARCH}
                element={
                  <GlobalRouteListener>
                    <Search />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.PRODUCT}
                element={
                  <GlobalRouteListener>
                    <Product />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.SYSTEM_DESIGNERS}
                element={
                  <GlobalRouteListener>
                    <SystemDesigners />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={`${ROUTES.FAMILY_SEARCH}/:catalogGroupId`}
                element={
                  <GlobalRouteListener>
                    <FamilySearch />
                  </GlobalRouteListener>
                }
              />
            </Routes>
          </Box>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            closeButton={<CloseOutlinedIcon sx={{ fontSize: "2rem", alignSelf: "center", color: "#757575" }} />}
            style={{
              width: "35rem",
            }}
            toastStyle={{
              color: toast.PALLETE_COLORS.BLACK,
            }}
          />
          <DrawerLaunch></DrawerLaunch>
        </Box>
      </BrowserRouter>
      {/* removing the existing advisor feedback button 
      and enabling the Let's connect button supplied from ra-header.
      */}
      {/* <AdvisorFeedback /> */}
      <OldAside />
      <RockwellFooter />
      <ProjectChangeDetection />
    </>
  );
};

export default LayoutView;
