import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Project } from "../../interfaces/Project";

interface SelProject {
  value?: Project | null;
}

const initialState: SelProject = {
  value: null,
};

export const project = createSlice({
  name: "selectedProject",
  initialState,
  reducers: {
    setSelProject: (state, action: PayloadAction<Project | null>) => {
      state.value = action.payload;
    },
  },
});

export const { setSelProject } = project.actions;

export const selectSelProject = (state: RootState) => state.project.value;

export default project.reducer;
